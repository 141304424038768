<template>
    <access-list class="layer-top"
                 @close="$router.push({name: 'projects'})"
    ></access-list>
</template>

<script>
import accessList from './components/user-list.vue'

export default {

    components:{
        accessList
    },

    data(){
        return {

        }
    },

    methods:{

    },
}
</script>