export const utcList = {
    '-720': 'UTC-12:00',
    '-660': 'UTC-11:00',
    '-600': 'UTC-10:00',
    '-510': 'UTC-09:30',
    '-540': 'UTC-09:00',
    '-420': 'UTC-07:00',
    '-360': 'UTC-06:00',
    '-300': 'UTC-05:00',
    '-210': 'UTC-04:30',
    '-240': 'UTC-04:00',
    '-180': 'UTC-03:00',
    '-120': 'UTC-02:00',
    '-60': 'UTC-01:00',
    '0': 'UTC+00:00',
    '60': 'UTC+01:00',
    '120': 'UTC+02:00',
    '180': 'UTC+03:00',
    '240': 'UTC+04:00',
    '270': 'UTC+04:30',
    '300': 'UTC+05:00',
    '330': 'UTC+05:30',
    '345': 'UTC+05:45',
    '360': 'UTC+06:00',
    '390': 'UTC+06:30',
    '420': 'UTC+07:00',
    '480': 'UTC+08:00',
    '525': 'UTC+08:45',
    '540': 'UTC+09:00',
    '570': 'UTC+09:30',
    '600': 'UTC+10:00',
    '660': 'UTC+11:00',
    '690': 'UTC+11:30',
    '720': 'UTC+12:00',
    '780': 'UTC+13:00',
    '840': 'UTC+14:00',
}