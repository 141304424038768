export default [
    { city: 'Москва', address: '', federal_district: '', region: '', timezone:  'UTC+3', geo_lat: '', geo_lon:  '' },
    { city: 'Санкт-Петербург', address: '', federal_district: '', region: '', timezone:  'UTC+3', geo_lat: '', geo_lon:  '' },
    { city: 'Севастополь', address: '', federal_district: '', region: '', timezone:  'UTC+3', geo_lat: '', geo_lon:  '' },

    { city: 'Адыгейск', address: 'Респ Адыгея, г Адыгейск', federal_district: 'Южный', region: 'Адыгея', timezone:  'UTC+3', geo_lat: '44.878414', geo_lon:  '39.190289' },
    { city: 'Майкоп', address: 'г Майкоп', federal_district: 'Южный', region: 'Адыгея', timezone:  'UTC+3', geo_lat: '44.6098268', geo_lon:  '40.1006606' },
    { city: 'Горно-Алтайск', address: 'г Горно-Алтайск', federal_district: 'Сибирский', region: 'Алтай', timezone:  'UTC+7', geo_lat: '51.9581028', geo_lon:  '85.9603235' },
    { city: 'Алейск', address: 'Алтайский край, г Алейск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '52.4922513', geo_lon:  '82.7793606' },
    { city: 'Барнаул', address: 'г Барнаул', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '53.3479968', geo_lon:  '83.7798064' },
    { city: 'Белокуриха', address: 'Алтайский край, г Белокуриха', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '51.996152', geo_lon:  '84.9839604' },
    { city: 'Бийск', address: 'Алтайский край, г Бийск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '52.5393864', geo_lon:  '85.2138453' },
    { city: 'Горняк', address: 'Алтайский край, г Горняк', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '50.9979622', geo_lon:  '81.4643358' },
    { city: 'Заринск', address: 'Алтайский край, г Заринск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '53.7063476', geo_lon:  '84.9315081' },
    { city: 'Змеиногорск', address: 'Алтайский край, г Змеиногорск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '51.1581094', geo_lon:  '82.1872547' },
    { city: 'Камень-на-Оби', address: 'Алтайский край, г Камень-на-Оби', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '53.7913974', geo_lon:  '81.3545053' },
    { city: 'Новоалтайск', address: 'Алтайский край, г Новоалтайск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '53.4119759', geo_lon:  '83.9311069' },
    { city: 'Рубцовск', address: 'Алтайский край, г Рубцовск', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '51.5012067', geo_lon:  '81.2078695' },
    { city: 'Славгород', address: 'Алтайский край, г Славгород', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '52.999463', geo_lon:  '78.6459232' },
    { city: 'Яровое', address: 'Алтайский край, г Яровое', federal_district: 'Сибирский', region: 'Алтайский', timezone:  'UTC+7', geo_lat: '52.9252146', geo_lon:  '78.5729775' },
    { city: 'Белогорск', address: 'Амурская обл, г Белогорск', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '50.9213415', geo_lon:  '128.4739471' },
    { city: 'Благовещенск', address: 'г Благовещенск', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '50.290659', geo_lon:  '127.527198' },
    { city: 'Завитинск', address: 'Амурская обл, г Завитинск', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '50.1064111', geo_lon:  '129.4391813' },
    { city: 'Зея', address: 'Амурская обл, г Зея', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '53.7340088', geo_lon:  '127.265787' },
    { city: 'Райчихинск', address: 'Амурская обл, г Райчихинск', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '49.7941615', geo_lon:  '129.4112492' },
    { city: 'Свободный', address: 'Амурская обл, г Свободный', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '51.3614103', geo_lon:  '128.1219729' },
    { city: 'Сковородино', address: 'Амурская обл, г Сковородино', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '53.9871095', geo_lon:  '123.9437205' },
    { city: 'Тында', address: 'Амурская обл, г Тында', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '55.1546441', geo_lon:  '124.7468904' },
    { city: 'Циолковский', address: 'Амурская обл, г Циолковский', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '51.762481', geo_lon:  '128.1219846' },
    { city: 'Шимановск', address: 'Амурская обл, г Шимановск', federal_district: 'Дальневосточный', region: 'Амурская', timezone:  'UTC+9', geo_lat: '52.0051886', geo_lon:  '127.7005458' },
    { city: 'Архангельск', address: 'г Архангельск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '64.5394289', geo_lon:  '40.5169606' },
    { city: 'Вельск', address: 'Архангельская обл, г Вельск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.066', geo_lon:  '42.1032789' },
    { city: 'Каргополь', address: 'Архангельская обл, г Каргополь', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.5009724', geo_lon:  '38.9636966' },
    { city: 'Коряжма', address: 'Архангельская обл, г Коряжма', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.2885948', geo_lon:  '47.1003015' },
    { city: 'Котлас', address: 'Архангельская обл, г Котлас', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.2528972', geo_lon:  '46.633242' },
    { city: 'Мезень', address: 'Архангельская обл, г Мезень', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '65.8398078', geo_lon:  '44.2532273' },
    { city: 'Мирный', address: 'Архангельская обл, г Мирный', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '62.7645265', geo_lon:  '40.3360076' },
    { city: 'Новодвинск', address: 'Архангельская обл, г Новодвинск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '64.4136851', geo_lon:  '40.8208143' },
    { city: 'Няндома', address: 'Архангельская обл, г Няндома', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.6654674', geo_lon:  '40.2062947' },
    { city: 'Онега', address: 'Архангельская обл, г Онега', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '63.9162928', geo_lon:  '38.0805031' },
    { city: 'Северодвинск', address: 'Архангельская обл, г Северодвинск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '64.5625385', geo_lon:  '39.8180934' },
    { city: 'Сольвычегодск', address: 'Архангельская обл, Котласский р-н, г Сольвычегодск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '61.3319616', geo_lon:  '46.920441' },
    { city: 'Шенкурск', address: 'Архангельская обл, г Шенкурск', federal_district: 'Северо-Западный', region: 'Архангельская', timezone:  'UTC+3', geo_lat: '62.1057272', geo_lon:  '42.8996973' },
    { city: 'Астрахань', address: 'г Астрахань', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '46.3655652', geo_lon:  '48.0559236' },
    { city: 'Ахтубинск', address: 'Астраханская обл, г Ахтубинск', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '48.2752034', geo_lon:  '46.1906462' },
    { city: 'Знаменск', address: 'Астраханская обл, г Знаменск', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '48.5866291', geo_lon:  '45.7368019' },
    { city: 'Камызяк', address: 'Астраханская обл, г Камызяк', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '46.110579', geo_lon:  '48.07333' },
    { city: 'Нариманов', address: 'Астраханская обл, г Нариманов', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '46.6916565', geo_lon:  '47.8502476' },
    { city: 'Харабали', address: 'Астраханская обл, г Харабали', federal_district: 'Южный', region: 'Астраханская', timezone:  'UTC+4', geo_lat: '47.408999', geo_lon:  '47.2525345' },
    { city: 'Агидель', address: 'Респ Башкортостан, г Агидель', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '55.8999056', geo_lon:  '53.9220144' },
    { city: 'Баймак', address: 'Респ Башкортостан, г Баймак', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '52.5912896', geo_lon:  '58.3110998' },
    { city: 'Белебей', address: 'Респ Башкортостан, г Белебей', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.1034582', geo_lon:  '54.1113129' },
    { city: 'Белорецк', address: 'Респ Башкортостан, г Белорецк', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '53.9676488', geo_lon:  '58.4100419' },
    { city: 'Бирск', address: 'Респ Башкортостан, г Бирск', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '55.4155753', geo_lon:  '55.5582214' },
    { city: 'Благовещенск', address: 'Респ Башкортостан, г Благовещенск', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '55.0499867', geo_lon:  '55.9553186' },
    { city: 'Давлеканово', address: 'Респ Башкортостан, г Давлеканово', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.2226707', geo_lon:  '55.0312373' },
    { city: 'Дюртюли', address: 'Респ Башкортостан, г Дюртюли', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '55.4848318', geo_lon:  '54.8524765' },
    { city: 'Ишимбай', address: 'Респ Башкортостан, г Ишимбай', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '53.4545764', geo_lon:  '56.0438751' },
    { city: 'Кумертау', address: 'Респ Башкортостан, г Кумертау', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '52.7564939', geo_lon:  '55.7970197' },
    { city: 'Межгорье', address: 'Респ Башкортостан, г Межгорье', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.2397689', geo_lon:  '57.9614547' },
    { city: 'Мелеуз', address: 'Респ Башкортостан, г Мелеуз', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '52.9589532', geo_lon:  '55.9282838' },
    { city: 'Нефтекамск', address: 'Респ Башкортостан, г Нефтекамск', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '56.088377', geo_lon:  '54.2483061' },
    { city: 'Октябрьский', address: 'Респ Башкортостан, г Октябрьский', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.4815311', geo_lon:  '53.4655972' },
    { city: 'Салават', address: 'Респ Башкортостан, г Салават', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '53.3616974', geo_lon:  '55.9245224' },
    { city: 'Сибай', address: 'Респ Башкортостан, г Сибай', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '52.7204651', geo_lon:  '58.6663783' },
    { city: 'Стерлитамак', address: 'Респ Башкортостан, г Стерлитамак', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '53.6300864', geo_lon:  '55.9317089' },
    { city: 'Туймазы', address: 'Респ Башкортостан, г Туймазы', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.5999224', geo_lon:  '53.6950623' },
    { city: 'Уфа', address: 'г Уфа', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.734944', geo_lon:  '55.9578468' },
    { city: 'Учалы', address: 'Респ Башкортостан, г Учалы', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '54.3067375', geo_lon:  '59.4125461' },
    { city: 'Янаул', address: 'Респ Башкортостан, г Янаул', federal_district: 'Приволжский', region: 'Башкортостан', timezone:  'UTC+5', geo_lat: '56.2650146', geo_lon:  '54.929907' },
    { city: 'Алексеевка', address: 'Белгородская обл, г Алексеевка', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.6299647', geo_lon:  '38.6880342' },
    { city: 'Белгород', address: 'г Белгород', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.5976472', geo_lon:  '36.5856652' },
    { city: 'Бирюч', address: 'Белгородская обл, г Бирюч', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.6484585', geo_lon:  '38.4005083' },
    { city: 'Валуйки', address: 'Белгородская обл, г Валуйки', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.2111207', geo_lon:  '38.0998772' },
    { city: 'Грайворон', address: 'Белгородская обл, г Грайворон', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.4862958', geo_lon:  '35.6663877' },
    { city: 'Губкин', address: 'Белгородская обл, г Губкин', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '51.2837123', geo_lon:  '37.5347759' },
    { city: 'Короча', address: 'Белгородская обл, г Короча', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.8129041', geo_lon:  '37.1896436' },
    { city: 'Новый Оскол', address: 'Белгородская обл, г Новый Оскол', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.7633747', geo_lon:  '37.8775484' },
    { city: 'Старый Оскол', address: 'Белгородская обл, г Старый Оскол', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '51.2967101', geo_lon:  '37.8350182' },
    { city: 'Строитель', address: 'Белгородская обл, г Строитель', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.7845099', geo_lon:  '36.4887648' },
    { city: 'Шебекино', address: 'Белгородская обл, г Шебекино', federal_district: 'Центральный', region: 'Белгородская', timezone:  'UTC+3', geo_lat: '50.4004883', geo_lon:  '36.8877889' },
    { city: 'Брянск', address: 'г Брянск', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.2419535', geo_lon:  '34.3652146' },
    { city: 'Дятьково', address: 'Брянская обл, г Дятьково', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.5958178', geo_lon:  '34.3551812' },
    { city: 'Жуковка', address: 'Брянская обл, г Жуковка', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.5340397', geo_lon:  '33.7302579' },
    { city: 'Злынка', address: 'Брянская обл, г Злынка', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.4267015', geo_lon:  '31.7360399' },
    { city: 'Карачев', address: 'Брянская обл, г Карачев', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.1296524', geo_lon:  '34.9888727' },
    { city: 'Клинцы', address: 'Брянская обл, г Клинцы', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.7529119', geo_lon:  '32.233911' },
    { city: 'Мглин', address: 'Брянская обл, г Мглин', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.0599771', geo_lon:  '32.8468129' },
    { city: 'Новозыбков', address: 'Брянская обл, г Новозыбков', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.537173', geo_lon:  '31.9357991' },
    { city: 'Почеп', address: 'Брянская обл, г Почеп', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.9154851', geo_lon:  '33.4744058' },
    { city: 'Севск', address: 'Брянская обл, г Севск', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.1483358', geo_lon:  '34.4918415' },
    { city: 'Сельцо', address: 'Брянская обл, г Сельцо', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.3739884', geo_lon:  '34.1059172' },
    { city: 'Стародуб', address: 'Брянская обл, г Стародуб', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.5852257', geo_lon:  '32.760403' },
    { city: 'Сураж', address: 'Брянская обл, г Сураж', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.0170888', geo_lon:  '32.3938878' },
    { city: 'Трубчевск', address: 'Брянская обл, г Трубчевск', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.5791734', geo_lon:  '33.7660547' },
    { city: 'Унеча', address: 'Брянская обл, г Унеча', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '52.8461199', geo_lon:  '32.6757629' },
    { city: 'Фокино', address: 'Брянская обл, г Фокино', federal_district: 'Центральный', region: 'Брянская', timezone:  'UTC+3', geo_lat: '53.4554145', geo_lon:  '34.4159238' },
    { city: 'Бабушкин', address: 'Респ Бурятия, Кабанский р-н, г Бабушкин', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '51.7112755', geo_lon:  '105.8673219' },
    { city: 'Гусиноозерск', address: 'Респ Бурятия, г Гусиноозерск', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '51.2865048', geo_lon:  '106.5230319' },
    { city: 'Закаменск', address: 'Респ Бурятия, г Закаменск', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '50.372713', geo_lon:  '103.286699' },
    { city: 'Кяхта', address: 'Респ Бурятия, г Кяхта', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '50.346543', geo_lon:  '106.4533516' },
    { city: 'Северобайкальск', address: 'Респ Бурятия, г Северобайкальск', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '55.635614', geo_lon:  '109.3361505' },
    { city: 'Улан-Удэ', address: 'г Улан-Удэ', federal_district: 'Дальневосточный', region: 'Бурятия', timezone:  'UTC+8', geo_lat: '51.8335853', geo_lon:  '107.5842223' },
    { city: 'Александров', address: 'Владимирская обл, г Александров', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.391819', geo_lon:  '38.7111123' },
    { city: 'Владимир', address: 'г Владимир', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.1280804', geo_lon:  '40.4084376' },
    { city: 'Вязники', address: 'Владимирская обл, г Вязники', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.29773', geo_lon:  '42.2687398' },
    { city: 'Гороховец', address: 'Владимирская обл, г Гороховец', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.2021036', geo_lon:  '42.6926111' },
    { city: 'Гусь-Хрустальный', address: 'Владимирская обл, г Гусь-Хрустальный', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.6198751', geo_lon:  '40.6579929' },
    { city: 'Камешково', address: 'Владимирская обл, г Камешково', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.3490152', geo_lon:  '40.9955183' },
    { city: 'Карабаново', address: 'Владимирская обл, Александровский р-н, г Карабаново', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.3131822', geo_lon:  '38.7034257' },
    { city: 'Киржач', address: 'Владимирская обл, г Киржач', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.1486863', geo_lon:  '38.8635701' },
    { city: 'Ковров', address: 'Владимирская обл, г Ковров', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.3554349', geo_lon:  '41.3170576' },
    { city: 'Кольчугино', address: 'Владимирская обл, г Кольчугино', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.3327254', geo_lon:  '39.391336' },
    { city: 'Костерево', address: 'Владимирская обл, Петушинский р-н, г Костерево', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9337222', geo_lon:  '39.6247398' },
    { city: 'Курлово', address: 'Владимирская обл, Гусь-Хрустальный р-н, г Курлово', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.452698', geo_lon:  '40.6124108' },
    { city: 'Лакинск', address: 'Владимирская обл, Собинский р-н, г Лакинск', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.0180587', geo_lon:  '39.956551' },
    { city: 'Меленки', address: 'Владимирская обл, г Меленки', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.3387515', geo_lon:  '41.6340046' },
    { city: 'Муром', address: 'Владимирская обл, г Муром', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.5630311', geo_lon:  '42.0231362' },
    { city: 'Петушки', address: 'Владимирская обл, г Петушки', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9298134', geo_lon:  '39.4508075' },
    { city: 'Покров', address: 'Владимирская обл, Петушинский р-н, г Покров', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9166398', geo_lon:  '39.1734526' },
    { city: 'Радужный', address: 'Владимирская обл, г Радужный', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9960277', geo_lon:  '40.3321855' },
    { city: 'Собинка', address: 'Владимирская обл, г Собинка', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9939169', geo_lon:  '40.0179653' },
    { city: 'Струнино', address: 'Владимирская обл, Александровский р-н, г Струнино', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.3749967', geo_lon:  '38.5839667' },
    { city: 'Судогда', address: 'Владимирская обл, г Судогда', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '55.9498056', geo_lon:  '40.8562939' },
    { city: 'Суздаль', address: 'Владимирская обл, г Суздаль', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.4274441', geo_lon:  '40.4525692' },
    { city: 'Юрьев-Польский', address: 'Владимирская обл, г Юрьев-Польский', federal_district: 'Центральный', region: 'Владимирская', timezone:  'UTC+3', geo_lat: '56.4937757', geo_lon:  '39.6680539' },
    { city: 'Волгоград', address: 'г Волгоград', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.7070042', geo_lon:  '44.5170339' },
    { city: 'Волжский', address: 'Волгоградская обл, г Волжский', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.7978209', geo_lon:  '44.7462538' },
    { city: 'Дубовка', address: 'Волгоградская обл, г Дубовка', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '49.0554742', geo_lon:  '44.8270085' },
    { city: 'Жирновск', address: 'Волгоградская обл, г Жирновск', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.9768814', geo_lon:  '44.7858202' },
    { city: 'Калач-на-Дону', address: 'Волгоградская обл, г Калач-на-Дону', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.6889024', geo_lon:  '43.5306303' },
    { city: 'Камышин', address: 'Волгоградская обл, г Камышин', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.0651529', geo_lon:  '45.3844202' },
    { city: 'Котельниково', address: 'Волгоградская обл, г Котельниково', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '47.6310259', geo_lon:  '43.1330872' },
    { city: 'Котово', address: 'Волгоградская обл, г Котово', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.3205766', geo_lon:  '44.8030699' },
    { city: 'Краснослободск', address: 'Волгоградская обл, Среднеахтубинский р-н, г Краснослободск', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.7068721', geo_lon:  '44.5630857' },
    { city: 'Ленинск', address: 'Волгоградская обл, г Ленинск', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.6936061', geo_lon:  '45.1992692' },
    { city: 'Михайловка', address: 'Волгоградская обл, г Михайловка', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.0708719', geo_lon:  '43.2401512' },
    { city: 'Николаевск', address: 'Волгоградская обл, г Николаевск', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.0165306', geo_lon:  '45.4731658' },
    { city: 'Новоаннинский', address: 'Волгоградская обл, г Новоаннинский', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.5296067', geo_lon:  '42.6666439' },
    { city: 'Палласовка', address: 'Волгоградская обл, г Палласовка', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.0500944', geo_lon:  '46.8804277' },
    { city: 'Петров Вал', address: 'Волгоградская обл, Камышинский р-н, г Петров Вал', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.1380557', geo_lon:  '45.20914' },
    { city: 'Серафимович', address: 'Волгоградская обл, г Серафимович', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '49.5663183', geo_lon:  '42.7360402' },
    { city: 'Суровикино', address: 'Волгоградская обл, г Суровикино', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '48.618917', geo_lon:  '42.8541163' },
    { city: 'Урюпинск', address: 'Волгоградская обл, г Урюпинск', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '50.7903789', geo_lon:  '42.0288513' },
    { city: 'Фролово', address: 'Волгоградская обл, г Фролово', federal_district: 'Южный', region: 'Волгоградская', timezone:  'UTC+4', geo_lat: '49.7649148', geo_lon:  '43.6648641' },
    { city: 'Бабаево', address: 'Вологодская обл, г Бабаево', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.3892583', geo_lon:  '35.9377058' },
    { city: 'Белозерск', address: 'Вологодская обл, г Белозерск', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '60.0308381', geo_lon:  '37.7890586' },
    { city: 'Великий Устюг', address: 'Вологодская обл, г Великий Устюг', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '60.7603913', geo_lon:  '46.3054414' },
    { city: 'Вологда', address: 'г Вологда', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.2483905', geo_lon:  '39.8355662' },
    { city: 'Вытегра', address: 'Вологодская обл, г Вытегра', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '61.0063465', geo_lon:  '36.4495137' },
    { city: 'Грязовец', address: 'Вологодская обл, г Грязовец', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '58.8757553', geo_lon:  '40.2485362' },
    { city: 'Кадников', address: 'Вологодская обл, Сокольский р-н, г Кадников', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.5037764', geo_lon:  '40.3441148' },
    { city: 'Кириллов', address: 'Вологодская обл, г Кириллов', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.8591523', geo_lon:  '38.3748782' },
    { city: 'Красавино', address: 'Вологодская обл, Великоустюгский р-н, г Красавино', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '60.9612823', geo_lon:  '46.4814116' },
    { city: 'Никольск', address: 'Вологодская обл, г Никольск', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.5351837', geo_lon:  '45.4576137' },
    { city: 'Сокол', address: 'Вологодская обл, г Сокол', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.4758605', geo_lon:  '40.1114187' },
    { city: 'Тотьма', address: 'Вологодская обл, г Тотьма', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.9734998', geo_lon:  '42.7589506' },
    { city: 'Устюжна', address: 'Вологодская обл, г Устюжна', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '58.8383117', geo_lon:  '36.4425478' },
    { city: 'Харовск', address: 'Вологодская обл, г Харовск', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.9479423', geo_lon:  '40.2000298' },
    { city: 'Череповец', address: 'Вологодская обл, г Череповец', federal_district: 'Северо-Западный', region: 'Вологодская', timezone:  'UTC+3', geo_lat: '59.1269212', geo_lon:  '37.9090497' },
    { city: 'Бобров', address: 'Воронежская обл, г Бобров', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.0901649', geo_lon:  '40.0318256' },
    { city: 'Богучар', address: 'Воронежская обл, г Богучар', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '49.9352454', geo_lon:  '40.5590801' },
    { city: 'Борисоглебск', address: 'Воронежская обл, г Борисоглебск', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.3655754', geo_lon:  '42.1008334' },
    { city: 'Бутурлиновка', address: 'Воронежская обл, г Бутурлиновка', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.8311818', geo_lon:  '40.5976923' },
    { city: 'Воронеж', address: 'г Воронеж', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.6593332', geo_lon:  '39.1969229' },
    { city: 'Калач', address: 'Воронежская обл, г Калач', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.4242134', geo_lon:  '41.0162014' },
    { city: 'Лиски', address: 'Воронежская обл, г Лиски', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.9945626', geo_lon:  '39.5184909' },
    { city: 'Нововоронеж', address: 'Воронежская обл, г Нововоронеж', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.3091524', geo_lon:  '39.2162843' },
    { city: 'Новохоперск', address: 'Воронежская обл, г Новохоперск', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.0952211', geo_lon:  '41.6173404' },
    { city: 'Острогожск', address: 'Воронежская обл, г Острогожск', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.8677905', geo_lon:  '39.0407746' },
    { city: 'Павловск', address: 'Воронежская обл, г Павловск', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.453455', geo_lon:  '40.136874' },
    { city: 'Поворино', address: 'Воронежская обл, г Поворино', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.1954419', geo_lon:  '42.2472726' },
    { city: 'Россошь', address: 'Воронежская обл, г Россошь', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '50.1701949', geo_lon:  '39.6226965' },
    { city: 'Семилуки', address: 'Воронежская обл, г Семилуки', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.6951644', geo_lon:  '39.0190454' },
    { city: 'Эртиль', address: 'Воронежская обл, г Эртиль', federal_district: 'Центральный', region: 'Воронежская', timezone:  'UTC+3', geo_lat: '51.830932', geo_lon:  '40.8074182' },
    { city: 'Буйнакск', address: 'Респ Дагестан, г Буйнакск', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.8214424', geo_lon:  '47.1165263' },
    { city: 'Дагестанские Огни', address: 'Респ Дагестан, г Дагестанские Огни', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.1152296', geo_lon:  '48.1939354' },
    { city: 'Дербент', address: 'Респ Дагестан, г Дербент', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.058966', geo_lon:  '48.2907452' },
    { city: 'Избербаш', address: 'Респ Дагестан, г Избербаш', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.5650962', geo_lon:  '47.8710051' },
    { city: 'Каспийск', address: 'Респ Дагестан, г Каспийск', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.8916007', geo_lon:  '47.6367066' },
    { city: 'Кизилюрт', address: 'Респ Дагестан, г Кизилюрт', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '43.203825', geo_lon:  '46.8729636' },
    { city: 'Кизляр', address: 'Респ Дагестан, г Кизляр', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '43.8484083', geo_lon:  '46.7233699' },
    { city: 'Махачкала', address: 'г Махачкала', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '42.9849159', geo_lon:  '47.5047181' },
    { city: 'Хасавюрт', address: 'Респ Дагестан, г Хасавюрт', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '43.2504665', geo_lon:  '46.5851292' },
    { city: 'Южно-Сухокумск', address: 'Респ Дагестан, г Южно-Сухокумск', federal_district: 'Северо-Кавказский', region: 'Дагестан', timezone:  'UTC+3', geo_lat: '44.6602467', geo_lon:  '45.6499523' },
    { city: 'Биробиджан', address: 'г Биробиджан', federal_district: 'Дальневосточный', region: 'Еврейская', timezone:  'UTC+10', geo_lat: '48.7946446', geo_lon:  '132.9217207' },
    { city: 'Облучье', address: 'Еврейская Аобл, г Облучье', federal_district: 'Дальневосточный', region: 'Еврейская', timezone:  'UTC+10', geo_lat: '49.0189345', geo_lon:  '131.0540102' },
    { city: 'Балей', address: 'Забайкальский край, г Балей', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '51.5823759', geo_lon:  '116.6379549' },
    { city: 'Борзя', address: 'Забайкальский край, г Борзя', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '50.3876058', geo_lon:  '116.5234779' },
    { city: 'Краснокаменск', address: 'Забайкальский край, г Краснокаменск', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '50.0929703', geo_lon:  '118.0323936' },
    { city: 'Могоча', address: 'Забайкальский край, г Могоча', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '53.7361398', geo_lon:  '119.7660867' },
    { city: 'Нерчинск', address: 'Забайкальский край, г Нерчинск', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '51.9594977', geo_lon:  '116.5852383' },
    { city: 'Петровск-Забайкальский', address: 'Забайкальский край, г Петровск-Забайкальский', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '51.2748592', geo_lon:  '108.846681' },
    { city: 'Сретенск', address: 'Забайкальский край, г Сретенск', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '52.2461454', geo_lon:  '117.7117842' },
    { city: 'Хилок', address: 'Забайкальский край, г Хилок', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '51.3634856', geo_lon:  '110.4590898' },
    { city: 'Чита', address: 'г Чита', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '52.0340142', geo_lon:  '113.4994' },
    { city: 'Шилка', address: 'Забайкальский край, г Шилка', federal_district: 'Дальневосточный', region: 'Забайкальский', timezone:  'UTC+9', geo_lat: '51.8497035', geo_lon:  '116.0334461' },
    { city: 'Вичуга', address: 'Ивановская обл, г Вичуга', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.2044698', geo_lon:  '41.9132201' },
    { city: 'Гаврилов Посад', address: 'Ивановская обл, г Гаврилов Посад', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.5586946', geo_lon:  '40.1228906' },
    { city: 'Заволжск', address: 'Ивановская обл, г Заволжск', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.4918141', geo_lon:  '42.1375625' },
    { city: 'Иваново', address: 'г Иваново', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.9993792', geo_lon:  '40.9728272' },
    { city: 'Кинешма', address: 'Ивановская обл, г Кинешма', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.4425463', geo_lon:  '42.168914' },
    { city: 'Комсомольск', address: 'Ивановская обл, г Комсомольск', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.0273052', geo_lon:  '40.3776851' },
    { city: 'Кохма', address: 'Ивановская обл, г Кохма', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.9324606', geo_lon:  '41.0931657' },
    { city: 'Наволоки', address: 'Ивановская обл, Кинешемский р-н, г Наволоки', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.4679066', geo_lon:  '41.9608002' },
    { city: 'Плес', address: 'Ивановская обл, Приволжский р-н, г Плес', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.4606031', geo_lon:  '41.5122672' },
    { city: 'Приволжск', address: 'Ивановская обл, г Приволжск', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.3805743', geo_lon:  '41.2808565' },
    { city: 'Пучеж', address: 'Ивановская обл, г Пучеж', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.9820688', geo_lon:  '43.1683321' },
    { city: 'Родники', address: 'Ивановская обл, г Родники', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.1025975', geo_lon:  '41.7298834' },
    { city: 'Тейково', address: 'Ивановская обл, г Тейково', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.8542719', geo_lon:  '40.5353874' },
    { city: 'Фурманов', address: 'Ивановская обл, г Фурманов', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.2539276', geo_lon:  '41.1054432' },
    { city: 'Шуя', address: 'Ивановская обл, г Шуя', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.8560234', geo_lon:  '41.3800939' },
    { city: 'Южа', address: 'Ивановская обл, г Южа', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '56.5926877', geo_lon:  '42.0458099' },
    { city: 'Юрьевец', address: 'Ивановская обл, г Юрьевец', federal_district: 'Центральный', region: 'Ивановская', timezone:  'UTC+3', geo_lat: '57.3177781', geo_lon:  '43.1110401' },
    { city: 'Карабулак', address: 'Респ Ингушетия, г Карабулак', federal_district: 'Северо-Кавказский', region: 'Ингушетия', timezone:  'UTC+3', geo_lat: '43.3055248', geo_lon:  '44.9094582' },
    { city: 'Магас', address: 'г Магас', federal_district: 'Северо-Кавказский', region: 'Ингушетия', timezone:  'UTC+3', geo_lat: '43.1688611', geo_lon:  '44.8131207' },
    { city: 'Малгобек', address: 'Респ Ингушетия, г Малгобек', federal_district: 'Северо-Кавказский', region: 'Ингушетия', timezone:  'UTC+3', geo_lat: '43.5096646', geo_lon:  '44.5901963' },
    { city: 'Назрань', address: 'Респ Ингушетия, г Назрань', federal_district: 'Северо-Кавказский', region: 'Ингушетия', timezone:  'UTC+3', geo_lat: '43.2257841', geo_lon:  '44.7645779' },
    { city: 'Сунжа', address: 'Респ Ингушетия, г Сунжа', federal_district: 'Северо-Кавказский', region: 'Ингушетия', timezone:  'UTC+3', geo_lat: '43.3204196', geo_lon:  '45.0476331' },
    { city: 'Алзамай', address: 'Иркутская обл, Нижнеудинский р-н, г Алзамай', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '55.5551233', geo_lon:  '98.6643699' },
    { city: 'Ангарск', address: 'Иркутская обл, г Ангарск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '52.544879', geo_lon:  '103.888543' },
    { city: 'Байкальск', address: 'Иркутская обл, Слюдянский р-н, г Байкальск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '51.5230393', geo_lon:  '104.1487532' },
    { city: 'Бирюсинск', address: 'Иркутская обл, Тайшетский р-н, г Бирюсинск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '55.9609167', geo_lon:  '97.8205348' },
    { city: 'Бодайбо', address: 'Иркутская обл, г Бодайбо', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '57.8468636', geo_lon:  '114.1866287' },
    { city: 'Братск', address: 'Иркутская обл, г Братск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '56.1513108', geo_lon:  '101.6340035' },
    { city: 'Вихоревка', address: 'Иркутская обл, Братский р-н, г Вихоревка', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '56.1208145', geo_lon:  '101.1702926' },
    { city: 'Железногорск-Илимский', address: 'Иркутская обл, г Железногорск-Илимский', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '56.5847888', geo_lon:  '104.114275' },
    { city: 'Зима', address: 'Иркутская обл, г Зима', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '53.920693', geo_lon:  '102.0491772' },
    { city: 'Иркутск', address: 'г Иркутск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '52.2864036', geo_lon:  '104.2807466' },
    { city: 'Киренск', address: 'Иркутская обл, г Киренск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '57.7756595', geo_lon:  '108.1109412' },
    { city: 'Нижнеудинск', address: 'Иркутская обл, г Нижнеудинск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '54.8968931', geo_lon:  '99.0314056' },
    { city: 'Саянск', address: 'Иркутская обл, г Саянск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '54.1107238', geo_lon:  '102.18015' },
    { city: 'Свирск', address: 'Иркутская обл, г Свирск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '53.0842668', geo_lon:  '103.3364093' },
    { city: 'Слюдянка', address: 'Иркутская обл, г Слюдянка', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '51.6563983', geo_lon:  '103.7187545' },
    { city: 'Тайшет', address: 'Иркутская обл, г Тайшет', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '55.9405334', geo_lon:  '98.0030145' },
    { city: 'Тулун', address: 'Иркутская обл, г Тулун', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '54.557162', geo_lon:  '100.5780603' },
    { city: 'Усолье-Сибирское', address: 'Иркутская обл, г Усолье-Сибирское', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '52.7565808', geo_lon:  '103.6388109' },
    { city: 'Усть-Илимск', address: 'Иркутская обл, г Усть-Илимск', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '57.9430504', geo_lon:  '102.7415734' },
    { city: 'Усть-Кут', address: 'Иркутская обл, г Усть-Кут', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '56.7928178', geo_lon:  '105.7757343' },
    { city: 'Черемхово', address: 'Иркутская обл, г Черемхово', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '53.1369095', geo_lon:  '103.0901268' },
    { city: 'Шелехов', address: 'Иркутская обл, г Шелехов', federal_district: 'Сибирский', region: 'Иркутская', timezone:  'UTC+8', geo_lat: '52.2102538', geo_lon:  '104.0973294' },
    { city: 'Баксан', address: 'Респ Кабардино-Балкарская, г Баксан', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.6819137', geo_lon:  '43.5345036' },
    { city: 'Майский', address: 'Респ Кабардино-Балкарская, г Майский', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.6281516', geo_lon:  '44.0517314' },
    { city: 'Нальчик', address: 'г Нальчик', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.4845464', geo_lon:  '43.60713' },
    { city: 'Нарткала', address: 'Респ Кабардино-Балкарская, г Нарткала', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.5578075', geo_lon:  '43.8575925' },
    { city: 'Прохладный', address: 'Респ Кабардино-Балкарская, г Прохладный', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.7589602', geo_lon:  '44.0102409' },
    { city: 'Терек', address: 'Респ Кабардино-Балкарская, г Терек', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.4839358', geo_lon:  '44.1402161' },
    { city: 'Тырныауз', address: 'Респ Кабардино-Балкарская, г Тырныауз', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.3981585', geo_lon:  '42.9213582' },
    { city: 'Чегем', address: 'Респ Кабардино-Балкарская, г Чегем', federal_district: 'Северо-Кавказский', region: 'Кабардино-Балкарская', timezone:  'UTC+3', geo_lat: '43.5671525', geo_lon:  '43.5865792' },
    { city: 'Багратионовск', address: 'Калининградская обл, г Багратионовск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.3866518', geo_lon:  '20.6418091' },
    { city: 'Балтийск', address: 'Калининградская обл, г Балтийск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.6513372', geo_lon:  '19.9140572' },
    { city: 'Гвардейск', address: 'Калининградская обл, г Гвардейск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.6588378', geo_lon:  '21.0501388' },
    { city: 'Гурьевск', address: 'Калининградская обл, г Гурьевск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.770638', geo_lon:  '20.6039767' },
    { city: 'Гусев', address: 'Калининградская обл, г Гусев', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.5915455', geo_lon:  '22.1942445' },
    { city: 'Зеленоградск', address: 'Калининградская обл, г Зеленоградск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.9600185', geo_lon:  '20.4753652' },
    { city: 'Калининград', address: 'г Калининград', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.7074702', geo_lon:  '20.5073241' },
    { city: 'Краснознаменск', address: 'Калининградская обл, г Краснознаменск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.9453423', geo_lon:  '22.4928745' },
    { city: 'Ладушкин', address: 'Калининградская обл, г Ладушкин', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.5692156', geo_lon:  '20.1690153' },
    { city: 'Мамоново', address: 'Калининградская обл, г Мамоново', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.4646036', geo_lon:  '19.9453482' },
    { city: 'Неман', address: 'Калининградская обл, г Неман', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '55.0316524', geo_lon:  '22.0324064' },
    { city: 'Нестеров', address: 'Калининградская обл, г Нестеров', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.6313814', geo_lon:  '22.5713736' },
    { city: 'Озерск', address: 'Калининградская обл, г Озерск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.4084705', geo_lon:  '22.0134438' },
    { city: 'Пионерский', address: 'Калининградская обл, г Пионерский', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.9516574', geo_lon:  '20.2277424' },
    { city: 'Полесск', address: 'Калининградская обл, г Полесск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.8625126', geo_lon:  '21.0998067' },
    { city: 'Правдинск', address: 'Калининградская обл, г Правдинск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.4430986', geo_lon:  '21.0085269' },
    { city: 'Приморск', address: 'Калининградская обл, Балтийский р-н, г Приморск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.7311437', geo_lon:  '19.9981926' },
    { city: 'Светлогорск', address: 'Калининградская обл, г Светлогорск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.9439286', geo_lon:  '20.1514295' },
    { city: 'Светлый', address: 'Калининградская обл, г Светлый', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.6772897', geo_lon:  '20.1357595' },
    { city: 'Славск', address: 'Калининградская обл, г Славск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '55.0449714', geo_lon:  '21.6742367' },
    { city: 'Советск', address: 'Калининградская обл, г Советск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '55.0809336', geo_lon:  '21.8886106' },
    { city: 'Черняховск', address: 'Калининградская обл, г Черняховск', federal_district: 'Северо-Западный', region: 'Калининградская', timezone:  'UTC+2', geo_lat: '54.6244751', geo_lon:  '21.7969062' },
    { city: 'Городовиковск', address: 'Респ Калмыкия, г Городовиковск', federal_district: 'Южный', region: 'Калмыкия', timezone:  'UTC+3', geo_lat: '46.0875083', geo_lon:  '41.935537' },
    { city: 'Лагань', address: 'Респ Калмыкия, г Лагань', federal_district: 'Южный', region: 'Калмыкия', timezone:  'UTC+3', geo_lat: '45.3930912', geo_lon:  '47.3432602' },
    { city: 'Элиста', address: 'г Элиста', federal_district: 'Южный', region: 'Калмыкия', timezone:  'UTC+3', geo_lat: '46.3083344', geo_lon:  '44.2702088' },
    { city: 'Балабаново', address: 'Калужская обл, Боровский р-н, г Балабаново', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.1773714', geo_lon:  '36.6566951' },
    { city: 'Белоусово', address: 'Калужская обл, Жуковский р-н, г Белоусово', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.0956803', geo_lon:  '36.677629' },
    { city: 'Боровск', address: 'Калужская обл, г Боровск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.2130096', geo_lon:  '36.4926251' },
    { city: 'Ермолино', address: 'Калужская обл, Боровский р-н, г Ермолино', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.1971758', geo_lon:  '36.5952722' },
    { city: 'Жиздра', address: 'Калужская обл, г Жиздра', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '53.7521926', geo_lon:  '34.7386592' },
    { city: 'Жуков', address: 'Калужская обл, г Жуков', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.0301833', geo_lon:  '36.7394903' },
    { city: 'Калуга', address: 'г Калуга', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.5059848', geo_lon:  '36.2516245' },
    { city: 'Киров', address: 'Калужская обл, г Киров', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.0790111', geo_lon:  '34.3076201' },
    { city: 'Козельск', address: 'Калужская обл, г Козельск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.0347201', geo_lon:  '35.780768' },
    { city: 'Кондрово', address: 'Калужская обл, г Кондрово', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.7959473', geo_lon:  '35.9274842' },
    { city: 'Кременки', address: 'Калужская обл, Жуковский р-н, г Кременки', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.8862447', geo_lon:  '37.1168701' },
    { city: 'Людиново', address: 'Калужская обл, г Людиново', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '53.8700828', geo_lon:  '34.4385915' },
    { city: 'Малоярославец', address: 'Калужская обл, г Малоярославец', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.0177123', geo_lon:  '36.4633603' },
    { city: 'Медынь', address: 'Калужская обл, г Медынь', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.9689785', geo_lon:  '35.8872168' },
    { city: 'Мещовск', address: 'Калужская обл, г Мещовск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.3191471', geo_lon:  '35.2816918' },
    { city: 'Мосальск', address: 'Калужская обл, г Мосальск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.4824939', geo_lon:  '34.9872239' },
    { city: 'Обнинск', address: 'Калужская обл, г Обнинск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '55.0943144', geo_lon:  '36.6121639' },
    { city: 'Сосенский', address: 'Калужская обл, Козельский р-н, г Сосенский', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.0566016', geo_lon:  '35.9621646' },
    { city: 'Спас-Деменск', address: 'Калужская обл, г Спас-Деменск', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.409922', geo_lon:  '34.0189631' },
    { city: 'Сухиничи', address: 'Калужская обл, г Сухиничи', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.097296', geo_lon:  '35.3443568' },
    { city: 'Таруса', address: 'Калужская обл, Тарусский р-н, г Таруса', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.7236477', geo_lon:  '37.1671' },
    { city: 'Юхнов', address: 'Калужская обл, г Юхнов', federal_district: 'Центральный', region: 'Калужская', timezone:  'UTC+3', geo_lat: '54.7446445', geo_lon:  '35.2424346' },
    { city: 'Вилючинск', address: 'Камчатский край, г Вилючинск', federal_district: 'Дальневосточный', region: 'Камчатский', timezone:  'UTC+12', geo_lat: '52.9302415', geo_lon:  '158.4057632' },
    { city: 'Елизово', address: 'Камчатский край, г Елизово', federal_district: 'Дальневосточный', region: 'Камчатский', timezone:  'UTC+12', geo_lat: '53.1830375', geo_lon:  '158.3883548' },
    { city: 'Петропавловск-Камчатский', address: 'г Петропавловск-Камчатский', federal_district: 'Дальневосточный', region: 'Камчатский', timezone:  'UTC+12', geo_lat: '53.036908', geo_lon:  '158.6559254' },
    { city: 'Карачаевск', address: 'Респ Карачаево-Черкесская, г Карачаевск', federal_district: 'Северо-Кавказский', region: 'Карачаево-Черкесская', timezone:  'UTC+3', geo_lat: '43.7732525', geo_lon:  '41.9143472' },
    { city: 'Теберда', address: 'Респ Карачаево-Черкесская, г Теберда', federal_district: 'Северо-Кавказский', region: 'Карачаево-Черкесская', timezone:  'UTC+3', geo_lat: '43.4437731', geo_lon:  '41.7415142' },
    { city: 'Усть-Джегута', address: 'Респ Карачаево-Черкесская, г Усть-Джегута', federal_district: 'Северо-Кавказский', region: 'Карачаево-Черкесская', timezone:  'UTC+3', geo_lat: '44.0838442', geo_lon:  '41.9711046' },
    { city: 'Черкесск', address: 'г Черкесск', federal_district: 'Северо-Кавказский', region: 'Карачаево-Черкесская', timezone:  'UTC+3', geo_lat: '44.2269425', geo_lon:  '42.0466704' },
    { city: 'Беломорск', address: 'Респ Карелия, г Беломорск', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '64.5378417', geo_lon:  '34.7799462' },
    { city: 'Кемь', address: 'Респ Карелия, Кемский р-н, г Кемь', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '64.9543539', geo_lon:  '34.5949263' },
    { city: 'Кондопога', address: 'Респ Карелия, г Кондопога', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '62.2059817', geo_lon:  '34.2682122' },
    { city: 'Костомукша', address: 'Респ Карелия, г Костомукша', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '64.5889398', geo_lon:  '30.6016832' },
    { city: 'Лахденпохья', address: 'Респ Карелия, г Лахденпохья', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '61.518881', geo_lon:  '30.1996116' },
    { city: 'Медвежьегорск', address: 'Респ Карелия, г Медвежьегорск', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '62.9127626', geo_lon:  '34.4568489' },
    { city: 'Олонец', address: 'Респ Карелия, г Олонец', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '60.9794025', geo_lon:  '32.9725519' },
    { city: 'Петрозаводск', address: 'г Петрозаводск', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '61.7891264', geo_lon:  '34.3596434' },
    { city: 'Питкяранта', address: 'Респ Карелия, г Питкяранта', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '61.5757191', geo_lon:  '31.4640557' },
    { city: 'Пудож', address: 'Респ Карелия, г Пудож', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '61.8058821', geo_lon:  '36.5329941' },
    { city: 'Сегежа', address: 'Респ Карелия, г Сегежа', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '63.7437572', geo_lon:  '34.3126982' },
    { city: 'Сортавала', address: 'Респ Карелия, г Сортавала', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '61.703367', geo_lon:  '30.6916998' },
    { city: 'Суоярви', address: 'Респ Карелия, г Суоярви', federal_district: 'Северо-Западный', region: 'Карелия', timezone:  'UTC+3', geo_lat: '62.0787293', geo_lon:  '32.3499386' },
    { city: 'Анжеро-Судженск', address: 'Кемеровская область - Кузбасс, г Анжеро-Судженск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '56.0786281', geo_lon:  '86.0201278' },
    { city: 'Белово', address: 'Кемеровская область - Кузбасс, г Белово', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.4220968', geo_lon:  '86.3037373' },
    { city: 'Березовский', address: 'Кемеровская область - Кузбасс, г Березовский', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '55.6693513', geo_lon:  '86.2744459' },
    { city: 'Гурьевск', address: 'Кемеровская область - Кузбасс, г Гурьевск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.2859263', geo_lon:  '85.9475985' },
    { city: 'Калтан', address: 'Кемеровская область - Кузбасс, г Калтан', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.5210919', geo_lon:  '87.2771636' },
    { city: 'Кемерово', address: 'г Кемерово', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '55.3910651', geo_lon:  '86.0467781' },
    { city: 'Киселевск', address: 'Кемеровская область - Кузбасс, г Киселевск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.0059999', geo_lon:  '86.6366116' },
    { city: 'Ленинск-Кузнецкий', address: 'Кемеровская область - Кузбасс, г Ленинск-Кузнецкий', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.6674492', geo_lon:  '86.1797324' },
    { city: 'Мариинск', address: 'Кемеровская область - Кузбасс, г Мариинск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '56.2127383', geo_lon:  '87.7454924' },
    { city: 'Междуреченск', address: 'Кемеровская область - Кузбасс, г Междуреченск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.6865289', geo_lon:  '88.0702754' },
    { city: 'Мыски', address: 'Кемеровская область - Кузбасс, г Мыски', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.7125695', geo_lon:  '87.8055646' },
    { city: 'Новокузнецк', address: 'Кемеровская область - Кузбасс, г Новокузнецк', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.794315', geo_lon:  '87.2142745' },
    { city: 'Осинники', address: 'Кемеровская область - Кузбасс, г Осинники', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.5988055', geo_lon:  '87.3371272' },
    { city: 'Полысаево', address: 'Кемеровская область - Кузбасс, г Полысаево', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.6055', geo_lon:  '86.2809208' },
    { city: 'Прокопьевск', address: 'Кемеровская область - Кузбасс, г Прокопьевск', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '53.8604265', geo_lon:  '86.7183577' },
    { city: 'Салаир', address: 'Кемеровская область - Кузбасс, Гурьевский р-н, г Салаир', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '54.2351735', geo_lon:  '85.8030733' },
    { city: 'Тайга', address: 'Кемеровская область - Кузбасс, г Тайга', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '56.0622131', geo_lon:  '85.6207182' },
    { city: 'Таштагол', address: 'Кемеровская область - Кузбасс, г Таштагол', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '52.759313', geo_lon:  '87.8476546' },
    { city: 'Топки', address: 'Кемеровская область - Кузбасс, г Топки', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '55.2764707', geo_lon:  '85.6152619' },
    { city: 'Юрга', address: 'Кемеровская область - Кузбасс, г Юрга', federal_district: 'Сибирский', region: 'Кемеровская область - Кузбасс', timezone:  'UTC+7', geo_lat: '55.7202694', geo_lon:  '84.8886399' },
    { city: 'Белая Холуница', address: 'Кировская обл, г Белая Холуница', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.8415239', geo_lon:  '50.8460699' },
    { city: 'Вятские Поляны', address: 'Кировская обл, г Вятские Поляны', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '56.2284817', geo_lon:  '51.0614855' },
    { city: 'Зуевка', address: 'Кировская обл, г Зуевка', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.4032152', geo_lon:  '51.1331606' },
    { city: 'Киров', address: 'г Киров', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.6035264', geo_lon:  '49.6679304' },
    { city: 'Кирово-Чепецк', address: 'Кировская обл, г Кирово-Чепецк', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.5559424', geo_lon:  '50.0316848' },
    { city: 'Кирс', address: 'Кировская обл, г Кирс', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '59.340056', geo_lon:  '52.2414437' },
    { city: 'Котельнич', address: 'Кировская обл, г Котельнич', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.3034832', geo_lon:  '48.3475597' },
    { city: 'Луза', address: 'Кировская обл, г Луза', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '60.6290609', geo_lon:  '47.2612445' },
    { city: 'Малмыж', address: 'Кировская обл, г Малмыж', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '56.5243844', geo_lon:  '50.6782739' },
    { city: 'Мураши', address: 'Кировская обл, г Мураши', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '59.3955383', geo_lon:  '48.9638417' },
    { city: 'Нолинск', address: 'Кировская обл, г Нолинск', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '57.5597284', geo_lon:  '49.93575' },
    { city: 'Омутнинск', address: 'Кировская обл, г Омутнинск', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.6698913', geo_lon:  '52.1894564' },
    { city: 'Орлов', address: 'Кировская обл, г Орлов', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.5389776', geo_lon:  '48.8927723' },
    { city: 'Слободской', address: 'Кировская обл, г Слободской', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '58.7311574', geo_lon:  '50.1669249' },
    { city: 'Советск', address: 'Кировская обл, г Советск', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '57.584196', geo_lon:  '48.9590272' },
    { city: 'Сосновка', address: 'Кировская обл, Вятскополянский р-н, г Сосновка', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '56.2532741', geo_lon:  '51.2833642' },
    { city: 'Уржум', address: 'Кировская обл, г Уржум', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '57.1097477', geo_lon:  '50.0058097' },
    { city: 'Яранск', address: 'Кировская обл, г Яранск', federal_district: 'Приволжский', region: 'Кировская', timezone:  'UTC+3', geo_lat: '57.3040326', geo_lon:  '47.8478737' },
    { city: 'Воркута', address: 'Респ Коми, г Воркута', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '67.4974082', geo_lon:  '64.061034' },
    { city: 'Вуктыл', address: 'Респ Коми, г Вуктыл', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '63.8615274', geo_lon:  '57.3165402' },
    { city: 'Емва', address: 'Респ Коми, г Емва', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '62.5965137', geo_lon:  '50.8732125' },
    { city: 'Инта', address: 'Респ Коми, г Инта', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '66.0367316', geo_lon:  '60.1152961' },
    { city: 'Микунь', address: 'Респ Коми, Усть-Вымский р-н, г Микунь', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '62.3579068', geo_lon:  '50.0719503' },
    { city: 'Печора', address: 'Респ Коми, г Печора', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '65.148584', geo_lon:  '57.2239777' },
    { city: 'Сосногорск', address: 'Респ Коми, г Сосногорск', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '63.5989841', geo_lon:  '53.876293' },
    { city: 'Сыктывкар', address: 'г Сыктывкар', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '61.6686617', geo_lon:  '50.8358151' },
    { city: 'Усинск', address: 'Респ Коми, г Усинск', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '65.994147', geo_lon:  '57.5569423' },
    { city: 'Ухта', address: 'Респ Коми, г Ухта', federal_district: 'Северо-Западный', region: 'Коми', timezone:  'UTC+3', geo_lat: '63.5565514', geo_lon:  '53.7014239' },
    { city: 'Буй', address: 'Костромская обл, г Буй', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.4733277', geo_lon:  '41.5306822' },
    { city: 'Волгореченск', address: 'Костромская обл, г Волгореченск', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '57.4424559', geo_lon:  '41.1594201' },
    { city: 'Галич', address: 'Костромская обл, г Галич', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.3828191', geo_lon:  '42.3654013' },
    { city: 'Кологрив', address: 'Костромская обл, г Кологрив', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.8269323', geo_lon:  '44.3184927' },
    { city: 'Кострома', address: 'г Кострома', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '57.8029621', geo_lon:  '40.9908264' },
    { city: 'Макарьев', address: 'Костромская обл, г Макарьев', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '57.8807152', geo_lon:  '43.8014197' },
    { city: 'Мантурово', address: 'Костромская обл, г Мантурово', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.3265441', geo_lon:  '44.757494' },
    { city: 'Нерехта', address: 'Костромская обл, г Нерехта', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '57.4543369', geo_lon:  '40.5723598' },
    { city: 'Нея', address: 'Костромская обл, г Нея', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.2943349', geo_lon:  '43.8780948' },
    { city: 'Солигалич', address: 'Костромская обл, г Солигалич', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '59.0784867', geo_lon:  '42.2878423' },
    { city: 'Чухлома', address: 'Костромская обл, г Чухлома', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.753421', geo_lon:  '42.6884958' },
    { city: 'Шарья', address: 'Костромская обл, г Шарья', federal_district: 'Центральный', region: 'Костромская', timezone:  'UTC+3', geo_lat: '58.3760542', geo_lon:  '45.4062414' },
    { city: 'Абинск', address: 'Краснодарский край, г Абинск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.8679655', geo_lon:  '38.1618157' },
    { city: 'Анапа', address: 'Краснодарский край, г Анапа', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.8950433', geo_lon:  '37.3163282' },
    { city: 'Апшеронск', address: 'Краснодарский край, г Апшеронск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.4584006', geo_lon:  '39.7299824' },
    { city: 'Армавир', address: 'Краснодарский край, г Армавир', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.0012149', geo_lon:  '41.1324168' },
    { city: 'Белореченск', address: 'Краснодарский край, г Белореченск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.7651876', geo_lon:  '39.8781494' },
    { city: 'Геленджик', address: 'Краснодарский край, г Геленджик', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.5630007', geo_lon:  '38.0790852' },
    { city: 'Горячий Ключ', address: 'Краснодарский край, г Горячий Ключ', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.6344864', geo_lon:  '39.1354738' },
    { city: 'Гулькевичи', address: 'Краснодарский край, г Гулькевичи', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.3605121', geo_lon:  '40.6918389' },
    { city: 'Ейск', address: 'Краснодарский край, г Ейск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '46.71157', geo_lon:  '38.2763895' },
    { city: 'Кореновск', address: 'Краснодарский край, г Кореновск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.4641703', geo_lon:  '39.458949' },
    { city: 'Краснодар', address: 'г Краснодар', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.0401604', geo_lon:  '38.9759647' },
    { city: 'Кропоткин', address: 'Краснодарский край, Кавказский р-н, г Кропоткин', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.4333007', geo_lon:  '40.5727951' },
    { city: 'Крымск', address: 'Краснодарский край, г Крымск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.9344404', geo_lon:  '37.9855795' },
    { city: 'Курганинск', address: 'Краснодарский край, г Курганинск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.8877025', geo_lon:  '40.5913245' },
    { city: 'Лабинск', address: 'Краснодарский край, г Лабинск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.6354363', geo_lon:  '40.7245341' },
    { city: 'Новокубанск', address: 'Краснодарский край, г Новокубанск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.1038699', geo_lon:  '41.0475175' },
    { city: 'Новороссийск', address: 'Краснодарский край, г Новороссийск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.7235026', geo_lon:  '37.7686135' },
    { city: 'Приморско-Ахтарск', address: 'Краснодарский край, г Приморско-Ахтарск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '46.0515432', geo_lon:  '38.1704875' },
    { city: 'Славянск-на-Кубани', address: 'Краснодарский край, г Славянск-на-Кубани', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.2603626', geo_lon:  '38.1259774' },
    { city: 'Сочи', address: 'Краснодарский край, г Сочи', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '43.5854551', geo_lon:  '39.7231548' },
    { city: 'Темрюк', address: 'Краснодарский край, г Темрюк', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.2610949', geo_lon:  '37.4454412' },
    { city: 'Тимашевск', address: 'Краснодарский край, г Тимашевск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.615923', geo_lon:  '38.9351837' },
    { city: 'Тихорецк', address: 'Краснодарский край, г Тихорецк', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.8546345', geo_lon:  '40.1260267' },
    { city: 'Туапсе', address: 'Краснодарский край, г Туапсе', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.1105335', geo_lon:  '39.0824904' },
    { city: 'Усть-Лабинск', address: 'Краснодарский край, г Усть-Лабинск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '45.2227359', geo_lon:  '39.6929577' },
    { city: 'Хадыженск', address: 'Краснодарский край, Апшеронский р-н, г Хадыженск', federal_district: 'Южный', region: 'Краснодарский', timezone:  'UTC+3', geo_lat: '44.4122963', geo_lon:  '39.5320258' },
    { city: 'Артемовск', address: 'Красноярский край, Курагинский р-н, г Артемовск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '54.3473075', geo_lon:  '93.4358377' },
    { city: 'Ачинск', address: 'Красноярский край, г Ачинск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.253907', geo_lon:  '90.4794397' },
    { city: 'Боготол', address: 'Красноярский край, г Боготол', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.2098468', geo_lon:  '89.5299336' },
    { city: 'Бородино', address: 'Красноярский край, г Бородино', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.9054121', geo_lon:  '94.9020967' },
    { city: 'Дивногорск', address: 'Красноярский край, г Дивногорск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.9576784', geo_lon:  '92.3800687' },
    { city: 'Дудинка', address: 'Красноярский край, г Дудинка', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '69.4031364', geo_lon:  '86.1907218' },
    { city: 'Енисейск', address: 'Красноярский край, г Енисейск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '58.4485261', geo_lon:  '92.1651083' },
    { city: 'Железногорск', address: 'Красноярский край, г Железногорск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.2529035', geo_lon:  '93.532273' },
    { city: 'Заозерный', address: 'Красноярский край, Рыбинский р-н, г Заозерный', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.9617752', geo_lon:  '94.7091491' },
    { city: 'Зеленогорск', address: 'Красноярский край, г Зеленогорск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.1131564', geo_lon:  '94.5888103' },
    { city: 'Игарка', address: 'Красноярский край, Туруханский р-н, г Игарка', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '67.4666901', geo_lon:  '86.5812794' },
    { city: 'Иланский', address: 'Красноярский край, г Иланский', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.2374037', geo_lon:  '96.067267' },
    { city: 'Канск', address: 'Красноярский край, г Канск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.2051282', geo_lon:  '95.7051096' },
    { city: 'Кодинск', address: 'Красноярский край, г Кодинск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '58.6032644', geo_lon:  '99.1797962' },
    { city: 'Красноярск', address: 'г Красноярск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.0093879', geo_lon:  '92.8524806' },
    { city: 'Лесосибирск', address: 'Красноярский край, г Лесосибирск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '58.221681', geo_lon:  '92.5037872' },
    { city: 'Минусинск', address: 'Красноярский край, г Минусинск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '53.7104586', geo_lon:  '91.6872907' },
    { city: 'Назарово', address: 'Красноярский край, г Назарово', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.0113799', geo_lon:  '90.4168775' },
    { city: 'Норильск', address: 'Красноярский край, г Норильск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '69.3489978', geo_lon:  '88.2009846' },
    { city: 'Сосновоборск', address: 'Красноярский край, г Сосновоборск', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '56.1202647', geo_lon:  '93.3354121' },
    { city: 'Ужур', address: 'Красноярский край, г Ужур', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.3141969', geo_lon:  '89.8333918' },
    { city: 'Уяр', address: 'Красноярский край, г Уяр', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.8131263', geo_lon:  '94.3282601' },
    { city: 'Шарыпово', address: 'Красноярский край, г Шарыпово', federal_district: 'Сибирский', region: 'Красноярский', timezone:  'UTC+7', geo_lat: '55.5389739', geo_lon:  '89.1801044' },
    { city: 'Ялта', address: 'Респ Крым, г Ялта, г Алупка', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.4164605', geo_lon:  '34.0444797' },
    { city: 'Алушта', address: 'Респ Крым, г Алушта', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.6764304', geo_lon:  '34.4100624' },
    { city: 'Армянск', address: 'Респ Крым, г Армянск', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '46.1059307', geo_lon:  '33.6910012' },
    { city: 'Бахчисарай', address: 'Респ Крым, Бахчисарайский р-н, г Бахчисарай', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.7514769', geo_lon:  '33.8752176' },
    { city: 'Белогорск', address: 'Респ Крым, Белогорский р-н, г Белогорск', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.057202', geo_lon:  '34.5999029' },
    { city: 'Джанкой', address: 'Респ Крым, г Джанкой', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.7092306', geo_lon:  '34.3883372' },
    { city: 'Евпатория', address: 'Респ Крым, г Евпатория', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.190629', geo_lon:  '33.367634' },
    { city: 'Керчь', address: 'Респ Крым, г Керчь', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.3562627', geo_lon:  '36.4674513' },
    { city: 'Красноперекопск', address: 'Респ Крым, г Красноперекопск', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.9537576', geo_lon:  '33.7921939' },
    { city: 'Саки', address: 'Респ Крым, г Саки', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.1341997', geo_lon:  '33.6033383' },
    { city: 'Симферополь', address: 'Респ Крым, г Симферополь', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.9482948', geo_lon:  '34.1001151' },
    { city: 'Старый Крым', address: 'Респ Крым, Кировский р-н, г Старый Крым', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.029058', geo_lon:  '35.0901474' },
    { city: 'Судак', address: 'Респ Крым, г Судак', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.8504679', geo_lon:  '34.9762034' },
    { city: 'Феодосия', address: 'Респ Крым, г Феодосия', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.0318393', geo_lon:  '35.3824259' },
    { city: 'Щелкино', address: 'Респ Крым, Ленинский р-н, г Щелкино', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '45.4288991', geo_lon:  '35.825165' },
    { city: 'Ялта', address: 'Респ Крым, г Ялта', federal_district: 'Южный', region: 'Крым', timezone:  'UTC+3', geo_lat: '44.4953612', geo_lon:  '34.166308' },
    { city: 'Далматово', address: 'Курганская обл, г Далматово', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '56.262114', geo_lon:  '62.9387011' },
    { city: 'Катайск', address: 'Курганская обл, г Катайск', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '56.290809', geo_lon:  '62.5800359' },
    { city: 'Курган', address: 'г Курган', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '55.4443883', geo_lon:  '65.3161963' },
    { city: 'Куртамыш', address: 'Курганская обл, г Куртамыш', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '54.9368539', geo_lon:  '64.4203722' },
    { city: 'Макушино', address: 'Курганская обл, г Макушино', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '55.2153947', geo_lon:  '67.2451705' },
    { city: 'Петухово', address: 'Курганская обл, г Петухово', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '55.0650077', geo_lon:  '67.8873408' },
    { city: 'Шадринск', address: 'Курганская обл, г Шадринск', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '56.0870344', geo_lon:  '63.6297182' },
    { city: 'Шумиха', address: 'Курганская обл, г Шумиха', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '55.2280246', geo_lon:  '63.2901272' },
    { city: 'Щучье', address: 'Курганская обл, г Щучье', federal_district: 'Уральский', region: 'Курганская', timezone:  'UTC+5', geo_lat: '55.2087637', geo_lon:  '62.7478548' },
    { city: 'Дмитриев', address: 'Курская обл, Дмитриевский р-н, г Дмитриев', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '52.1268464', geo_lon:  '35.0739038' },
    { city: 'Железногорск', address: 'Курская обл, г Железногорск', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '52.3380202', geo_lon:  '35.3516867' },
    { city: 'Курск', address: 'г Курск', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.7303637', geo_lon:  '36.1925603' },
    { city: 'Курчатов', address: 'Курская обл, г Курчатов', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.6604083', geo_lon:  '35.6572224' },
    { city: 'Льгов', address: 'Курская обл, г Льгов', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.6597148', geo_lon:  '35.2612491' },
    { city: 'Обоянь', address: 'Курская обл, г Обоянь', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.2119324', geo_lon:  '36.2755133' },
    { city: 'Рыльск', address: 'Курская обл, г Рыльск', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.5681314', geo_lon:  '34.6802597' },
    { city: 'Суджа', address: 'Курская обл, г Суджа', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.1918927', geo_lon:  '35.2720915' },
    { city: 'Фатеж', address: 'Курская обл, г Фатеж', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '52.0917728', geo_lon:  '35.8538706' },
    { city: 'Щигры', address: 'Курская обл, г Щигры', federal_district: 'Центральный', region: 'Курская', timezone:  'UTC+3', geo_lat: '51.8786014', geo_lon:  '36.8910945' },
    { city: 'Бокситогорск', address: 'Ленинградская обл, г Бокситогорск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.4734797', geo_lon:  '33.845688' },
    { city: 'Волосово', address: 'Ленинградская обл, г Волосово', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.4445408', geo_lon:  '29.4923355' },
    { city: 'Волхов', address: 'Ленинградская обл, г Волхов', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.9005958', geo_lon:  '32.3520756' },
    { city: 'Всеволожск', address: 'Ленинградская обл, г Всеволожск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.0191278', geo_lon:  '30.6456718' },
    { city: 'Выборг', address: 'Ленинградская обл, г Выборг', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.7130801', geo_lon:  '28.7328336' },
    { city: 'Высоцк', address: 'Ленинградская обл, Выборгский р-н, г Высоцк', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.6296236', geo_lon:  '28.5709314' },
    { city: 'Гатчина', address: 'Ленинградская обл, г Гатчина', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.565237', geo_lon:  '30.1282473' },
    { city: 'Ивангород', address: 'Ленинградская обл, Кингисеппский р-н, г Ивангород', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.3766119', geo_lon:  '28.2231659' },
    { city: 'Каменногорск', address: 'Ленинградская обл, Выборгский р-н, г Каменногорск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.950855', geo_lon:  '29.1308372' },
    { city: 'Кингисепп', address: 'Ленинградская обл, г Кингисепп', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.3740435', geo_lon:  '28.6112444' },
    { city: 'Кириши', address: 'Ленинградская обл, г Кириши', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.4742862', geo_lon:  '32.0624947' },
    { city: 'Кировск', address: 'Ленинградская обл, г Кировск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.8754216', geo_lon:  '30.981364' },
    { city: 'Коммунар', address: 'Ленинградская обл, Гатчинский р-н, г Коммунар', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.6215133', geo_lon:  '30.3934125' },
    { city: 'Кудрово', address: 'Ленинградская обл, Всеволожский р-н, г Кудрово', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.9075226', geo_lon:  '30.5121008' },
    { city: 'Лодейное Поле', address: 'Ленинградская обл, г Лодейное Поле', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.7320936', geo_lon:  '33.5521022' },
    { city: 'Луга', address: 'Ленинградская обл, г Луга', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '58.7374031', geo_lon:  '29.8465776' },
    { city: 'Любань', address: 'Ленинградская обл, Тосненский р-н, г Любань', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.3493847', geo_lon:  '31.2484801' },
    { city: 'Мурино', address: 'Ленинградская обл, Всеволожский р-н, г Мурино', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.044862', geo_lon:  '30.4571456' },
    { city: 'Никольское', address: 'Ленинградская обл, Тосненский р-н, г Никольское', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.7043309', geo_lon:  '30.7874571' },
    { city: 'Новая Ладога', address: 'Ленинградская обл, Волховский р-н, г Новая Ладога', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.1100135', geo_lon:  '32.3141203' },
    { city: 'Отрадное', address: 'Ленинградская обл, Кировский р-н, г Отрадное', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.7726848', geo_lon:  '30.7988557' },
    { city: 'Пикалево', address: 'Ленинградская обл, Бокситогорский р-н, г Пикалево', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.5132022', geo_lon:  '34.1772776' },
    { city: 'Подпорожье', address: 'Ленинградская обл, г Подпорожье', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.9127549', geo_lon:  '34.1567664' },
    { city: 'Приморск', address: 'Ленинградская обл, Выборгский р-н, г Приморск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.3660209', geo_lon:  '28.6135772' },
    { city: 'Приозерск', address: 'Ленинградская обл, г Приозерск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '61.0330896', geo_lon:  '30.1587851' },
    { city: 'Светогорск', address: 'Ленинградская обл, Выборгский р-н, г Светогорск', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '61.1111282', geo_lon:  '28.8725865' },
    { city: 'Сертолово', address: 'Ленинградская обл, Всеволожский р-н, г Сертолово', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.1446932', geo_lon:  '30.2095918' },
    { city: 'Сланцы', address: 'Ленинградская обл, г Сланцы', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.1178185', geo_lon:  '28.0881475' },
    { city: 'Сосновый Бор', address: 'Ленинградская обл, г Сосновый Бор', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.8772884', geo_lon:  '29.1291619' },
    { city: 'Сясьстрой', address: 'Ленинградская обл, Волховский р-н, г Сясьстрой', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '60.1401739', geo_lon:  '32.5601559' },
    { city: 'Тихвин', address: 'Ленинградская обл, г Тихвин', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.6272904', geo_lon:  '33.5072731' },
    { city: 'Тосно', address: 'Ленинградская обл, г Тосно', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.5407098', geo_lon:  '30.877812' },
    { city: 'Шлиссельбург', address: 'Ленинградская обл, Кировский р-н, г Шлиссельбург', federal_district: 'Северо-Западный', region: 'Ленинградская', timezone:  'UTC+3', geo_lat: '59.9443714', geo_lon:  '31.0333365' },
    { city: 'Грязи', address: 'Липецкая обл, г Грязи', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '52.4874097', geo_lon:  '39.9331142' },
    { city: 'Данков', address: 'Липецкая обл, г Данков', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '53.2577411', geo_lon:  '39.1456184' },
    { city: 'Елец', address: 'Липецкая обл, г Елец', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '52.6152411', geo_lon:  '38.5289342' },
    { city: 'Задонск', address: 'Липецкая обл, г Задонск', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '52.4004179', geo_lon:  '38.9205032' },
    { city: 'Лебедянь', address: 'Липецкая обл, г Лебедянь', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '53.0156117', geo_lon:  '39.143536' },
    { city: 'Липецк', address: 'г Липецк', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '52.610249', geo_lon:  '39.5947883' },
    { city: 'Усмань', address: 'Липецкая обл, г Усмань', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '52.0442648', geo_lon:  '39.726401' },
    { city: 'Чаплыгин', address: 'Липецкая обл, г Чаплыгин', federal_district: 'Центральный', region: 'Липецкая', timezone:  'UTC+3', geo_lat: '53.24048', geo_lon:  '39.9670973' },
    { city: 'Магадан', address: 'г Магадан', federal_district: 'Дальневосточный', region: 'Магаданская', timezone:  'UTC+11', geo_lat: '59.5681332', geo_lon:  '150.8084956' },
    { city: 'Сусуман', address: 'Магаданская обл, г Сусуман', federal_district: 'Дальневосточный', region: 'Магаданская', timezone:  'UTC+11', geo_lat: '62.7805882', geo_lon:  '148.1540281' },
    { city: 'Волжск', address: 'Респ Марий Эл, г Волжск', federal_district: 'Приволжский', region: 'Марий Эл', timezone:  'UTC+3', geo_lat: '55.8623156', geo_lon:  '48.3715083' },
    { city: 'Звенигово', address: 'Респ Марий Эл, г Звенигово', federal_district: 'Приволжский', region: 'Марий Эл', timezone:  'UTC+3', geo_lat: '55.9738571', geo_lon:  '48.0170245' },
    { city: 'Йошкар-Ола', address: 'г Йошкар-Ола', federal_district: 'Приволжский', region: 'Марий Эл', timezone:  'UTC+3', geo_lat: '56.6343662', geo_lon:  '47.8999706' },
    { city: 'Козьмодемьянск', address: 'Респ Марий Эл, г Козьмодемьянск', federal_district: 'Приволжский', region: 'Марий Эл', timezone:  'UTC+3', geo_lat: '56.3334036', geo_lon:  '46.546675' },
    { city: 'Ардатов', address: 'Респ Мордовия, г Ардатов', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.8465544', geo_lon:  '46.2411232' },
    { city: 'Инсар', address: 'Респ Мордовия, г Инсар', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '53.8770022', geo_lon:  '44.3696566' },
    { city: 'Ковылкино', address: 'Респ Мордовия, г Ковылкино', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.0391072', geo_lon:  '43.9191539' },
    { city: 'Краснослободск', address: 'Респ Мордовия, г Краснослободск', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.4248207', geo_lon:  '43.7845011' },
    { city: 'Рузаевка', address: 'Респ Мордовия, г Рузаевка', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.0581967', geo_lon:  '44.9490466' },
    { city: 'Саранск', address: 'г Саранск', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.1809332', geo_lon:  '45.1862632' },
    { city: 'Темников', address: 'Респ Мордовия, г Темников', federal_district: 'Приволжский', region: 'Мордовия', timezone:  'UTC+3', geo_lat: '54.6310583', geo_lon:  '43.2161099' },
    { city: '', address: 'г Москва', federal_district: 'Центральный', region: 'Москва', timezone:  'UTC+3', geo_lat: '55.7540471', geo_lon:  '37.620405' },
    { city: 'Апрелевка', address: 'Московская обл, Наро-Фоминский р-н, г Апрелевка', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5276918', geo_lon:  '37.065143' },
    { city: 'Балашиха', address: 'Московская обл, г Балашиха', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.796389', geo_lon:  '37.938283' },
    { city: 'Белоозерский', address: 'Московская обл, Воскресенский р-н, пгт Белоозерский', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.4595766', geo_lon:  '38.4389742' },
    { city: 'Бронницы', address: 'Московская обл, г Бронницы', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.4255379', geo_lon:  '38.264145' },
    { city: 'Верея', address: 'Московская обл, Наро-Фоминский р-н, г Верея', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.3673997', geo_lon:  '36.1563454' },
    { city: 'Видное', address: 'Московская обл, г Видное', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.551725', geo_lon:  '37.7061984' },
    { city: 'Волоколамск', address: 'Московская обл, г Волоколамск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.0356499', geo_lon:  '35.9585112' },
    { city: 'Воскресенск', address: 'Московская обл, г Воскресенск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.3071519', geo_lon:  '38.7027953' },
    { city: 'Высоковск', address: 'Московская обл, Клинский р-н, г Высоковск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.3359513', geo_lon:  '36.5251837' },
    { city: 'Голицыно', address: 'Московская обл, Одинцовский р-н, г Голицыно', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.6190582', geo_lon:  '36.9856793' },
    { city: 'Дедовск', address: 'Московская обл, Истринский р-н, г Дедовск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8703276', geo_lon:  '37.1245043' },
    { city: 'Дзержинский', address: 'Московская обл, г Дзержинский', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.6240869', geo_lon:  '37.8440276' },
    { city: 'Дмитров', address: 'Московская обл, г Дмитров', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.3477457', geo_lon:  '37.526672' },
    { city: 'Долгопрудный', address: 'Московская обл, г Долгопрудный', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9385999', geo_lon:  '37.5101021' },
    { city: 'Домодедово', address: 'Московская обл, г Домодедово', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.4363283', geo_lon:  '37.7664984' },
    { city: 'Дрезна', address: 'Московская обл, Орехово-Зуевский р-н, г Дрезна', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7443143', geo_lon:  '38.8498479' },
    { city: 'Дубна', address: 'Московская обл, г Дубна', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.741786', geo_lon:  '37.1757223' },
    { city: 'Егорьевск', address: 'Московская обл, г Егорьевск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.3830113', geo_lon:  '39.0358317' },
    { city: 'Жуковский', address: 'Московская обл, г Жуковский', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.599803', geo_lon:  '38.1224298' },
    { city: 'Зарайск', address: 'Московская обл, г Зарайск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.762456', geo_lon:  '38.8850978' },
    { city: 'Звенигород', address: 'Московская обл, г Звенигород', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7297089', geo_lon:  '36.8554029' },
    { city: 'Ивантеевка', address: 'Московская обл, г Ивантеевка', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9741665', geo_lon:  '37.9207539' },
    { city: 'Истра', address: 'Московская обл, г Истра', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9062267', geo_lon:  '36.8601454' },
    { city: 'Кашира', address: 'Московская обл, г Кашира', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.853337', geo_lon:  '38.1904392' },
    { city: 'Клин', address: 'Московская обл, г Клин', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.3425605', geo_lon:  '36.7240032' },
    { city: 'Коломна', address: 'Московская обл, г Коломна', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.102814', geo_lon:  '38.7531002' },
    { city: 'Королев', address: 'Московская обл, г Королев', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9161773', geo_lon:  '37.8545415' },
    { city: 'Котельники', address: 'Московская обл, г Котельники', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.6597925', geo_lon:  '37.8631156' },
    { city: 'Красноармейск', address: 'Московская обл, г Красноармейск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.105426', geo_lon:  '38.140838' },
    { city: 'Красногорск', address: 'Московская обл, г Красногорск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8317203', geo_lon:  '37.3295266' },
    { city: 'Краснозаводск', address: 'Московская обл, Сергиево-Посадский р-н, г Краснозаводск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.4409979', geo_lon:  '38.2320307' },
    { city: 'Краснознаменск', address: 'Московская обл, г Краснознаменск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5978959', geo_lon:  '37.0393709' },
    { city: 'Кубинка', address: 'Московская обл, Одинцовский р-н, г Кубинка', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5754955', geo_lon:  '36.6951995' },
    { city: 'Куровское', address: 'Московская обл, Орехово-Зуевский р-н, г Куровское', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5792277', geo_lon:  '38.9207723' },
    { city: 'Ликино-Дулёво', address: 'Московская обл, г Ликино-Дулёво', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7078257', geo_lon:  '38.9578093' },
    { city: 'Лобня', address: 'Московская обл, г Лобня', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.0328881', geo_lon:  '37.4614035' },
    { city: 'Лосино-Петровский', address: 'Московская обл, г Лосино-Петровский', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8713214', geo_lon:  '38.200599' },
    { city: 'Луховицы', address: 'Московская обл, г Луховицы', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.9652077', geo_lon:  '39.0260266' },
    { city: 'Лыткарино', address: 'Московская обл, г Лыткарино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5778163', geo_lon:  '37.9033507' },
    { city: 'Люберцы', address: 'Московская обл, г Люберцы', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7003989', geo_lon:  '37.9606238' },
    { city: 'Можайск', address: 'Московская обл, г Можайск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5069259', geo_lon:  '36.024043' },
    { city: 'Мытищи', address: 'Московская обл, г Мытищи', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9105782', geo_lon:  '37.7363579' },
    { city: 'Наро-Фоминск', address: 'Московская обл, г Наро-Фоминск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.386185', geo_lon:  '36.734484' },
    { city: 'Ногинск', address: 'Московская обл, г Ногинск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8686133', geo_lon:  '38.4622104' },
    { city: 'Одинцово', address: 'Московская обл, г Одинцово', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.6789455', geo_lon:  '37.263686' },
    { city: 'Озеры', address: 'Московская обл, г Озеры', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.8541006', geo_lon:  '38.5599196' },
    { city: 'Орехово-Зуево', address: 'Московская обл, г Орехово-Зуево', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8151421', geo_lon:  '38.9869822' },
    { city: 'Павловский Посад', address: 'Московская обл, г Павловский Посад', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7807244', geo_lon:  '38.6596983' },
    { city: 'Пересвет', address: 'Московская обл, Сергиево-Посадский р-н, г Пересвет', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.4158326', geo_lon:  '38.1733534' },
    { city: 'Подольск', address: 'Московская обл, г Подольск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.4389322', geo_lon:  '37.5703482' },
    { city: 'Протвино', address: 'Московская обл, г Протвино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.8705984', geo_lon:  '37.2182749' },
    { city: 'Пушкино', address: 'Московская обл, г Пушкино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.0103638', geo_lon:  '37.8471403' },
    { city: 'Пущино', address: 'Московская обл, г Пущино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.8324412', geo_lon:  '37.6210346' },
    { city: 'Раменское', address: 'Московская обл, г Раменское', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5495132', geo_lon:  '38.2728914' },
    { city: 'Реутов', address: 'Московская обл, г Реутов', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7582621', geo_lon:  '37.8618553' },
    { city: 'Рошаль', address: 'Московская обл, г Рошаль', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.6632776', geo_lon:  '39.8656147' },
    { city: 'Руза', address: 'Московская обл, г Руза', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7014744', geo_lon:  '36.1959206' },
    { city: 'Сергиев Посад', address: 'Московская обл, г Сергиев Посад', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.3062548', geo_lon:  '38.1502661' },
    { city: 'Серпухов', address: 'Московская обл, г Серпухов', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.9226466', geo_lon:  '37.4033859' },
    { city: 'Солнечногорск', address: 'Московская обл, г Солнечногорск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.185102', geo_lon:  '36.977631' },
    { city: 'Старая Купавна', address: 'Московская обл, Ногинский р-н, г Старая Купавна', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8038532', geo_lon:  '38.1426842' },
    { city: 'Ступино', address: 'Московская обл, г Ступино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '54.9238928', geo_lon:  '38.1186623' },
    { city: 'Талдом', address: 'Московская обл, г Талдом', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.7308564', geo_lon:  '37.5276003' },
    { city: 'Фрязино', address: 'Московская обл, г Фрязино', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9590588', geo_lon:  '38.0410235' },
    { city: 'Химки', address: 'Московская обл, г Химки', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.888657', geo_lon:  '37.4303702' },
    { city: 'Хотьково', address: 'Московская обл, Сергиево-Посадский р-н, г Хотьково', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.2516982', geo_lon:  '37.9396017' },
    { city: 'Черноголовка', address: 'Московская обл, г Черноголовка', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.0100707', geo_lon:  '38.3792047' },
    { city: 'Чехов', address: 'Московская обл, г Чехов', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.1508011', geo_lon:  '37.4533252' },
    { city: 'Шатура', address: 'Московская обл, г Шатура', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.5777427', geo_lon:  '39.5445712' },
    { city: 'Щелково', address: 'Московская обл, г Щелково', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.9233801', geo_lon:  '37.9783707' },
    { city: 'Электрогорск', address: 'Московская обл, г Электрогорск', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.8780241', geo_lon:  '38.7806752' },
    { city: 'Электросталь', address: 'Московская обл, г Электросталь', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7847291', geo_lon:  '38.4447045' },
    { city: 'Электроугли', address: 'Московская обл, Ногинский р-н, г Электроугли', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '55.7170877', geo_lon:  '38.2192563' },
    { city: 'Яхрома', address: 'Московская обл, Дмитровский р-н, г Яхрома', federal_district: 'Центральный', region: 'Московская', timezone:  'UTC+3', geo_lat: '56.2890516', geo_lon:  '37.4831799' },
    { city: 'Апатиты', address: 'Мурманская обл, г Апатиты', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.5677761', geo_lon:  '33.4067929' },
    { city: 'Гаджиево', address: 'Мурманская обл, г Гаджиево', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.2491311', geo_lon:  '33.315341' },
    { city: 'Заозерск', address: 'Мурманская обл, г Заозерск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.4003584', geo_lon:  '32.4501496' },
    { city: 'Заполярный', address: 'Мурманская обл, Печенгский р-н, г Заполярный', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.4132852', geo_lon:  '30.7984312' },
    { city: 'Кандалакша', address: 'Мурманская обл, г Кандалакша', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.1567974', geo_lon:  '32.4143218' },
    { city: 'Кировск', address: 'Мурманская обл, г Кировск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.6150424', geo_lon:  '33.663735' },
    { city: 'Ковдор', address: 'Мурманская обл, г Ковдор', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.5661417', geo_lon:  '30.4741941' },
    { city: 'Кола', address: 'Мурманская обл, г Кола', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '68.8786028', geo_lon:  '33.0262299' },
    { city: 'Мончегорск', address: 'Мурманская обл, г Мончегорск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.9386153', geo_lon:  '32.9359719' },
    { city: 'Мурманск', address: 'г Мурманск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.007721', geo_lon:  '33.0685865' },
    { city: 'Оленегорск', address: 'Мурманская обл, г Оленегорск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '68.1422058', geo_lon:  '33.2669407' },
    { city: 'Островной', address: 'Мурманская обл, г Островной', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '68.0510344', geo_lon:  '39.5077846' },
    { city: 'Полярные Зори', address: 'Мурманская обл, г Полярные Зори', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '67.373084', geo_lon:  '32.4975636' },
    { city: 'Полярный', address: 'Мурманская обл, г Полярный', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.1989583', geo_lon:  '33.4508591' },
    { city: 'Североморск', address: 'Мурманская обл, г Североморск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.0766801', geo_lon:  '33.4177759' },
    { city: 'Снежногорск', address: 'Мурманская обл, г Снежногорск', federal_district: 'Северо-Западный', region: 'Мурманская', timezone:  'UTC+3', geo_lat: '69.1921409', geo_lon:  '33.2383502' },
    { city: 'Нарьян-Мар', address: 'г Нарьян-Мар', federal_district: 'Северо-Западный', region: 'Ненецкий', timezone:  'UTC+3', geo_lat: '67.6379672', geo_lon:  '53.0069565' },
    { city: 'Арзамас', address: 'Нижегородская обл, г Арзамас', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.3945814', geo_lon:  '43.8408141' },
    { city: 'Балахна', address: 'Нижегородская обл, г Балахна', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.495036', geo_lon:  '43.5758423' },
    { city: 'Богородск', address: 'Нижегородская обл, г Богородск', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.1020828', geo_lon:  '43.5135442' },
    { city: 'Бор', address: 'Нижегородская обл, г Бор', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.3565458', geo_lon:  '44.0646481' },
    { city: 'Ветлуга', address: 'Нижегородская обл, г Ветлуга', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '57.8559204', geo_lon:  '45.7761957' },
    { city: 'Володарск', address: 'Нижегородская обл, г Володарск', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.2169751', geo_lon:  '43.1596417' },
    { city: 'Ворсма', address: 'Нижегородская обл, Павловский р-н, г Ворсма', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.989943', geo_lon:  '43.2718859' },
    { city: 'Выкса', address: 'Нижегородская обл, г Выкса', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.3207727', geo_lon:  '42.1678834' },
    { city: 'Горбатов', address: 'Нижегородская обл, Павловский р-н, г Горбатов', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.1307769', geo_lon:  '43.0626185' },
    { city: 'Городец', address: 'Нижегородская обл, г Городец', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.6449218', geo_lon:  '43.4723104' },
    { city: 'Дзержинск', address: 'Нижегородская обл, г Дзержинск', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.2376047', geo_lon:  '43.4599416' },
    { city: 'Заволжье', address: 'Нижегородская обл, Городецкий р-н, г Заволжье', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.6404286', geo_lon:  '43.3872492' },
    { city: 'Княгинино', address: 'Нижегородская обл, г Княгинино', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.8205915', geo_lon:  '45.032337' },
    { city: 'Кстово', address: 'Нижегородская обл, г Кстово', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.1432084', geo_lon:  '44.1664198' },
    { city: 'Кулебаки', address: 'Нижегородская обл, г Кулебаки', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.4296181', geo_lon:  '42.5125538' },
    { city: 'Лукоянов', address: 'Нижегородская обл, г Лукоянов', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.0326225', geo_lon:  '44.4933807' },
    { city: 'Лысково', address: 'Нижегородская обл, г Лысково', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.0262359', geo_lon:  '45.035771' },
    { city: 'Навашино', address: 'Нижегородская обл, г Навашино', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.543811', geo_lon:  '42.1887089' },
    { city: 'Нижний Новгород', address: 'г Нижний Новгород', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.3240627', geo_lon:  '44.0053913' },
    { city: 'Павлово', address: 'Нижегородская обл, г Павлово', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.9797564', geo_lon:  '43.0995042' },
    { city: 'Первомайск', address: 'Нижегородская обл, г Первомайск', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '54.8675792', geo_lon:  '43.8013992' },
    { city: 'Перевоз', address: 'Нижегородская обл, г Перевоз', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.5967718', geo_lon:  '44.5448369' },
    { city: 'Саров', address: 'Нижегородская обл, г Саров', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '54.9228268', geo_lon:  '43.3448089' },
    { city: 'Семенов', address: 'Нижегородская обл, г Семенов', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.7889794', geo_lon:  '44.4902885' },
    { city: 'Сергач', address: 'Нижегородская обл, г Сергач', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '55.5201515', geo_lon:  '45.4813231' },
    { city: 'Урень', address: 'Нижегородская обл, г Урень', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '57.4612572', geo_lon:  '45.7833293' },
    { city: 'Чкаловск', address: 'Нижегородская обл, г Чкаловск', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '56.7651262', geo_lon:  '43.242077' },
    { city: 'Шахунья', address: 'Нижегородская обл, г Шахунья', federal_district: 'Приволжский', region: 'Нижегородская', timezone:  'UTC+3', geo_lat: '57.6764293', geo_lon:  '46.6129009' },
    { city: 'Боровичи', address: 'Новгородская обл, г Боровичи', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.3840197', geo_lon:  '33.9175929' },
    { city: 'Валдай', address: 'Новгородская обл, г Валдай', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '57.9823766', geo_lon:  '33.2369436' },
    { city: 'Великий Новгород', address: 'г Великий Новгород', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.5213846', geo_lon:  '31.2755394' },
    { city: 'Малая Вишера', address: 'Новгородская обл, г Малая Вишера', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.8458379', geo_lon:  '32.2247401' },
    { city: 'Окуловка', address: 'Новгородская обл, г Окуловка', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.3910296', geo_lon:  '33.2901557' },
    { city: 'Пестово', address: 'Новгородская обл, г Пестово', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.5973723', geo_lon:  '35.8143898' },
    { city: 'Сольцы', address: 'Новгородская обл, г Сольцы', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '58.1201281', geo_lon:  '30.309351' },
    { city: 'Старая Русса', address: 'Новгородская обл, г Старая Русса', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '57.990737', geo_lon:  '31.3554897' },
    { city: 'Холм', address: 'Новгородская обл, г Холм', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '57.145108', geo_lon:  '31.1787499' },
    { city: 'Чудово', address: 'Новгородская обл, г Чудово', federal_district: 'Северо-Западный', region: 'Новгородская', timezone:  'UTC+3', geo_lat: '59.1248394', geo_lon:  '31.6866241' },
    { city: 'Барабинск', address: 'Новосибирская обл, Барабинский р-н, г Барабинск', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.35146', geo_lon:  '78.3464506' },
    { city: 'Бердск', address: 'Новосибирская обл, г Бердск', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '54.7582156', geo_lon:  '83.1070605' },
    { city: 'Болотное', address: 'Новосибирская обл, г Болотное', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.6692421', geo_lon:  '84.3906889' },
    { city: 'Искитим', address: 'Новосибирская обл, г Искитим', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '54.6267603', geo_lon:  '83.2951244' },
    { city: 'Карасук', address: 'Новосибирская обл, г Карасук', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '53.7343189', geo_lon:  '78.0422967' },
    { city: 'Каргат', address: 'Новосибирская обл, г Каргат', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.1945666', geo_lon:  '80.2829495' },
    { city: 'Куйбышев', address: 'Новосибирская обл, Куйбышевский р-н, г Куйбышев', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.4685094', geo_lon:  '78.3242048' },
    { city: 'Купино', address: 'Новосибирская обл, г Купино', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '54.366055', geo_lon:  '77.2973368' },
    { city: 'Новосибирск', address: 'г Новосибирск', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.028191', geo_lon:  '82.9211489' },
    { city: 'Обь', address: 'Новосибирская обл, г Обь', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '54.9945576', geo_lon:  '82.6937181' },
    { city: 'Татарск', address: 'Новосибирская обл, Татарский р-н, г Татарск', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.2146167', geo_lon:  '75.9739914' },
    { city: 'Тогучин', address: 'Новосибирская обл, г Тогучин', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.2251631', geo_lon:  '84.4104118' },
    { city: 'Черепаново', address: 'Новосибирская обл, г Черепаново', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '54.2206476', geo_lon:  '83.3724521' },
    { city: 'Чулым', address: 'Новосибирская обл, г Чулым', federal_district: 'Сибирский', region: 'Новосибирская', timezone:  'UTC+7', geo_lat: '55.0906867', geo_lon:  '80.9592508' },
    { city: 'Исилькуль', address: 'Омская обл, г Исилькуль', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '54.9096002', geo_lon:  '71.2816284' },
    { city: 'Калачинск', address: 'Омская обл, г Калачинск', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '55.0598155', geo_lon:  '74.5653644' },
    { city: 'Называевск', address: 'Омская обл, г Называевск', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '55.5689323', geo_lon:  '71.3503426' },
    { city: 'Омск', address: 'г Омск', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '54.9848566', geo_lon:  '73.3674517' },
    { city: 'Тара', address: 'Омская обл, г Тара', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '56.9160511', geo_lon:  '74.3649194' },
    { city: 'Тюкалинск', address: 'Омская обл, г Тюкалинск', federal_district: 'Сибирский', region: 'Омская', timezone:  'UTC+6', geo_lat: '55.8703415', geo_lon:  '72.1954747' },
    { city: 'Абдулино', address: 'Оренбургская обл, г Абдулино', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '53.6778906', geo_lon:  '53.6472483' },
    { city: 'Бугуруслан', address: 'Оренбургская обл, г Бугуруслан', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '53.6523728', geo_lon:  '52.4326853' },
    { city: 'Бузулук', address: 'Оренбургская обл, г Бузулук', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '52.7881277', geo_lon:  '52.2624877' },
    { city: 'Гай', address: 'Оренбургская обл, г Гай', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.4649189', geo_lon:  '58.4436875' },
    { city: 'Кувандык', address: 'Оренбургская обл, г Кувандык', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.4783857', geo_lon:  '57.3612636' },
    { city: 'Медногорск', address: 'Оренбургская обл, г Медногорск', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.4037617', geo_lon:  '57.583163' },
    { city: 'Новотроицк', address: 'Оренбургская обл, г Новотроицк', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.1964202', geo_lon:  '58.3018192' },
    { city: 'Оренбург', address: 'г Оренбург', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.7875092', geo_lon:  '55.1018828' },
    { city: 'Орск', address: 'Оренбургская обл, г Орск', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.2294282', geo_lon:  '58.4752777' },
    { city: 'Соль-Илецк', address: 'Оренбургская обл, г Соль-Илецк', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.1633736', geo_lon:  '54.9896726' },
    { city: 'Сорочинск', address: 'Оренбургская обл, г Сорочинск', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '52.426685', geo_lon:  '53.1542745' },
    { city: 'Ясный', address: 'Оренбургская обл, г Ясный', federal_district: 'Приволжский', region: 'Оренбургская', timezone:  'UTC+5', geo_lat: '51.036838', geo_lon:  '59.874344' },
    { city: 'Болхов', address: 'Орловская обл, г Болхов', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '53.4438496', geo_lon:  '36.0076833' },
    { city: 'Дмитровск', address: 'Орловская обл, г Дмитровск', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '52.5054851', geo_lon:  '35.1415009' },
    { city: 'Ливны', address: 'Орловская обл, г Ливны', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '52.4284558', geo_lon:  '37.6039506' },
    { city: 'Малоархангельск', address: 'Орловская обл, г Малоархангельск', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '52.4002192', geo_lon:  '36.5038579' },
    { city: 'Мценск', address: 'Орловская обл, г Мценск', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '53.2788438', geo_lon:  '36.5749105' },
    { city: 'Новосиль', address: 'Орловская обл, г Новосиль', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '52.97454', geo_lon:  '37.0437146' },
    { city: 'Орёл', address: 'г Орёл', federal_district: 'Центральный', region: 'Орловская', timezone:  'UTC+3', geo_lat: '52.9671298', geo_lon:  '36.0696427' },
    { city: 'Белинский', address: 'Пензенская обл, г Белинский', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '52.9640996', geo_lon:  '43.4183212' },
    { city: 'Городище', address: 'Пензенская обл, г Городище', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.2726916', geo_lon:  '45.7026142' },
    { city: 'Заречный', address: 'Пензенская обл, г Заречный', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.1960836', geo_lon:  '45.1689907' },
    { city: 'Каменка', address: 'Пензенская обл, г Каменка', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.1855463', geo_lon:  '44.0469717' },
    { city: 'Кузнецк', address: 'Пензенская обл, г Кузнецк', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.1130888', geo_lon:  '46.605092' },
    { city: 'Нижний Ломов', address: 'Пензенская обл, г Нижний Ломов', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.5300905', geo_lon:  '43.6730217' },
    { city: 'Никольск', address: 'Пензенская обл, г Никольск', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.7137496', geo_lon:  '46.0799857' },
    { city: 'Пенза', address: 'г Пенза', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.1753314', geo_lon:  '45.0348625' },
    { city: 'Сердобск', address: 'Пензенская обл, г Сердобск', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '52.4697595', geo_lon:  '44.2122414' },
    { city: 'Спасск', address: 'Пензенская обл, г Спасск', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.9271974', geo_lon:  '43.1859073' },
    { city: 'Сурск', address: 'Пензенская обл, Городищенский р-н, г Сурск', federal_district: 'Приволжский', region: 'Пензенская', timezone:  'UTC+3', geo_lat: '53.0761357', geo_lon:  '45.6910796' },
    { city: 'Александровск', address: 'Пермский край, г Александровск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.1613221', geo_lon:  '57.5763459' },
    { city: 'Березники', address: 'Пермский край, г Березники', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.4079923', geo_lon:  '56.8039427' },
    { city: 'Верещагино', address: 'Пермский край, г Верещагино', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.0797571', geo_lon:  '54.6581309' },
    { city: 'Горнозаводск', address: 'Пермский край, г Горнозаводск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.3742532', geo_lon:  '58.3231716' },
    { city: 'Гремячинск', address: 'Пермский край, г Гремячинск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.5626082', geo_lon:  '57.8520572' },
    { city: 'Губаха', address: 'Пермский край, г Губаха', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.8371721', geo_lon:  '57.554533' },
    { city: 'Добрянка', address: 'Пермский край, г Добрянка', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.469685', geo_lon:  '56.4130737' },
    { city: 'Кизел', address: 'Пермский край, г Кизел', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.0512783', geo_lon:  '57.6471028' },
    { city: 'Красновишерск', address: 'Пермский край, г Красновишерск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '60.3901321', geo_lon:  '57.0535682' },
    { city: 'Краснокамск', address: 'Пермский край, г Краснокамск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.0822065', geo_lon:  '55.7479936' },
    { city: 'Кудымкар', address: 'Пермский край, г Кудымкар', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.0167925', geo_lon:  '54.6572508' },
    { city: 'Кунгур', address: 'Пермский край, г Кунгур', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '57.4283296', geo_lon:  '56.9438656' },
    { city: 'Лысьва', address: 'Пермский край, г Лысьва', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.0995875', geo_lon:  '57.8086825' },
    { city: 'Нытва', address: 'Пермский край, г Нытва', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '57.9336725', geo_lon:  '55.3356084' },
    { city: 'Оса', address: 'Пермский край, г Оса', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '57.2889515', geo_lon:  '55.4688668' },
    { city: 'Оханск', address: 'Пермский край, г Оханск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '57.7180034', geo_lon:  '55.3872469' },
    { city: 'Очер', address: 'Пермский край, г Очер', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '57.8852686', geo_lon:  '54.7161091' },
    { city: 'Пермь', address: 'г Пермь', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.0102583', geo_lon:  '56.2342034' },
    { city: 'Соликамск', address: 'Пермский край, г Соликамск', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.6482998', geo_lon:  '56.771009' },
    { city: 'Усолье', address: 'Пермский край, г Усолье', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '59.4277573', geo_lon:  '56.6837872' },
    { city: 'Чайковский', address: 'Пермский край, г Чайковский', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '56.7781501', geo_lon:  '54.1477965' },
    { city: 'Чердынь', address: 'Пермский край, г Чердынь', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '60.4011933', geo_lon:  '56.4799933' },
    { city: 'Чермоз', address: 'Пермский край, Ильинский р-н, г Чермоз', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.7842834', geo_lon:  '56.1507138' },
    { city: 'Чернушка', address: 'Пермский край, г Чернушка', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '56.5160768', geo_lon:  '56.0763049' },
    { city: 'Чусовой', address: 'Пермский край, г Чусовой', federal_district: 'Приволжский', region: 'Пермский', timezone:  'UTC+5', geo_lat: '58.2974596', geo_lon:  '57.8193615' },
    { city: 'Арсеньев', address: 'Приморский край, г Арсеньев', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '44.1622031', geo_lon:  '133.2696209' },
    { city: 'Артем', address: 'Приморский край, г Артем', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '43.3501675', geo_lon:  '132.1596175' },
    { city: 'Большой Камень', address: 'Приморский край, г Большой Камень', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '43.1111742', geo_lon:  '132.3480082' },
    { city: 'Владивосток', address: 'г Владивосток', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '43.1164904', geo_lon:  '131.8823937' },
    { city: 'Дальнегорск', address: 'Приморский край, г Дальнегорск', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '44.5539457', geo_lon:  '135.5662716' },
    { city: 'Дальнереченск', address: 'Приморский край, г Дальнереченск', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '45.9308483', geo_lon:  '133.7316907' },
    { city: 'Лесозаводск', address: 'Приморский край, г Лесозаводск', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '45.4780092', geo_lon:  '133.4186199' },
    { city: 'Находка', address: 'Приморский край, г Находка', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '42.8239372', geo_lon:  '132.8927361' },
    { city: 'Партизанск', address: 'Приморский край, г Партизанск', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '43.1280578', geo_lon:  '133.1264567' },
    { city: 'Спасск-Дальний', address: 'Приморский край, г Спасск-Дальний', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '44.5901175', geo_lon:  '132.8157288' },
    { city: 'Уссурийск', address: 'Приморский край, г Уссурийск', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '43.7971818', geo_lon:  '131.9518229' },
    { city: 'Фокино', address: 'Приморский край, г Фокино', federal_district: 'Дальневосточный', region: 'Приморский', timezone:  'UTC+10', geo_lat: '42.9706317', geo_lon:  '132.4110196' },
    { city: 'Великие Луки', address: 'Псковская обл, г Великие Луки', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.332208', geo_lon:  '30.5508641' },
    { city: 'Гдов', address: 'Псковская обл, г Гдов', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '58.7432429', geo_lon:  '27.8264809' },
    { city: 'Дно', address: 'Псковская обл, г Дно', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.826974', geo_lon:  '29.9629389' },
    { city: 'Невель', address: 'Псковская обл, г Невель', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.0201973', geo_lon:  '29.9239983' },
    { city: 'Новоржев', address: 'Псковская обл, г Новоржев', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.029807', geo_lon:  '29.3433083' },
    { city: 'Новосокольники', address: 'Псковская обл, г Новосокольники', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.3408431', geo_lon:  '30.1527573' },
    { city: 'Опочка', address: 'Псковская обл, г Опочка', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.710725', geo_lon:  '28.6717519' },
    { city: 'Остров', address: 'Псковская обл, г Остров', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.3451528', geo_lon:  '28.3437593' },
    { city: 'Печоры', address: 'Псковская обл, г Печоры', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.8145817', geo_lon:  '27.622259' },
    { city: 'Порхов', address: 'Псковская обл, г Порхов', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.7765219', geo_lon:  '29.5436626' },
    { city: 'Псков', address: 'г Псков', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.8194415', geo_lon:  '28.3317198' },
    { city: 'Пустошка', address: 'Псковская обл, г Пустошка', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.3374813', geo_lon:  '29.3668055' },
    { city: 'Пыталово', address: 'Псковская обл, г Пыталово', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '57.0637952', geo_lon:  '27.9236214' },
    { city: 'Себеж', address: 'Псковская обл, г Себеж', federal_district: 'Северо-Западный', region: 'Псковская', timezone:  'UTC+3', geo_lat: '56.2908554', geo_lon:  '28.4724326' },
    { city: 'Азов', address: 'Ростовская обл, г Азов', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.1121589', geo_lon:  '39.4232555' },
    { city: 'Аксай', address: 'Ростовская обл, г Аксай', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.2676314', geo_lon:  '39.8756872' },
    { city: 'Батайск', address: 'Ростовская обл, г Батайск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.1383299', geo_lon:  '39.7507179' },
    { city: 'Белая Калитва', address: 'Ростовская обл, г Белая Калитва', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.1769737', geo_lon:  '40.8033529' },
    { city: 'Волгодонск', address: 'Ростовская обл, г Волгодонск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.5165338', geo_lon:  '42.1984951' },
    { city: 'Гуково', address: 'Ростовская обл, г Гуково', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.0448904', geo_lon:  '39.9484672' },
    { city: 'Донецк', address: 'Ростовская обл, г Донецк', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.3350706', geo_lon:  '39.945891' },
    { city: 'Зверево', address: 'Ростовская обл, г Зверево', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.0435487', geo_lon:  '40.1265822' },
    { city: 'Зерноград', address: 'Ростовская обл, г Зерноград', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '46.8495958', geo_lon:  '40.312837' },
    { city: 'Каменск-Шахтинский', address: 'Ростовская обл, г Каменск-Шахтинский', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.3205326', geo_lon:  '40.2689583' },
    { city: 'Константиновск', address: 'Ростовская обл, г Константиновск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.5773717', geo_lon:  '41.0967445' },
    { city: 'Красный Сулин', address: 'Ростовская обл, г Красный Сулин', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.8831311', geo_lon:  '40.0781298' },
    { city: 'Миллерово', address: 'Ростовская обл, г Миллерово', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.9260077', geo_lon:  '40.3984087' },
    { city: 'Морозовск', address: 'Ростовская обл, г Морозовск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '48.3511807', geo_lon:  '41.8309225' },
    { city: 'Новочеркасск', address: 'Ростовская обл, г Новочеркасск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.411919', geo_lon:  '40.1042098' },
    { city: 'Новошахтинск', address: 'Ростовская обл, г Новошахтинск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.757773', geo_lon:  '39.9363697' },
    { city: 'Пролетарск', address: 'Ростовская обл, г Пролетарск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '46.7038963', geo_lon:  '41.727594' },
    { city: 'Ростов-на-Дону', address: 'г Ростов-на-Дону', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.2224566', geo_lon:  '39.718803' },
    { city: 'Сальск', address: 'Ростовская обл, г Сальск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '46.4752095', geo_lon:  '41.5410415' },
    { city: 'Семикаракорск', address: 'Ростовская обл, г Семикаракорск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.5177337', geo_lon:  '40.8114167' },
    { city: 'Таганрог', address: 'Ростовская обл, г Таганрог', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.2094917', geo_lon:  '38.9350989' },
    { city: 'Цимлянск', address: 'Ростовская обл, г Цимлянск', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.6477448', geo_lon:  '42.0931505' },
    { city: 'Шахты', address: 'Ростовская обл, г Шахты', federal_district: 'Южный', region: 'Ростовская', timezone:  'UTC+3', geo_lat: '47.7085287', geo_lon:  '40.2159846' },
    { city: 'Касимов', address: 'Рязанская обл, г Касимов', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.9373475', geo_lon:  '41.3913211' },
    { city: 'Кораблино', address: 'Рязанская обл, г Кораблино', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '53.9246659', geo_lon:  '40.0227745' },
    { city: 'Михайлов', address: 'Рязанская обл, г Михайлов', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.2297402', geo_lon:  '39.0091481' },
    { city: 'Новомичуринск', address: 'Рязанская обл, Пронский р-н, г Новомичуринск', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.0376572', geo_lon:  '39.74654' },
    { city: 'Рыбное', address: 'Рязанская обл, г Рыбное', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.7256164', geo_lon:  '39.5134398' },
    { city: 'Ряжск', address: 'Рязанская обл, г Ряжск', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '53.7067584', geo_lon:  '40.0522274' },
    { city: 'Рязань', address: 'г Рязань', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.6254445', geo_lon:  '39.7358609' },
    { city: 'Сасово', address: 'Рязанская обл, г Сасово', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.3508885', geo_lon:  '41.9117422' },
    { city: 'Скопин', address: 'Рязанская обл, г Скопин', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '53.823543', geo_lon:  '39.5492421' },
    { city: 'Спас-Клепики', address: 'Рязанская обл, г Спас-Клепики', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '55.1291721', geo_lon:  '40.1745338' },
    { city: 'Спасск-Рязанский', address: 'Рязанская обл, г Спасск-Рязанский', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.4070719', geo_lon:  '40.3763426' },
    { city: 'Шацк', address: 'Рязанская обл, г Шацк', federal_district: 'Центральный', region: 'Рязанская', timezone:  'UTC+3', geo_lat: '54.0287312', geo_lon:  '41.7181803' },
    { city: 'Жигулевск', address: 'Самарская обл, г Жигулевск', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.4011981', geo_lon:  '49.4945176' },
    { city: 'Кинель', address: 'Самарская обл, г Кинель', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.2210298', geo_lon:  '50.6343776' },
    { city: 'Нефтегорск', address: 'Самарская обл, г Нефтегорск', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '52.7972914', geo_lon:  '51.1637392' },
    { city: 'Новокуйбышевск', address: 'Самарская обл, г Новокуйбышевск', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.0994565', geo_lon:  '49.9477382' },
    { city: 'Октябрьск', address: 'Самарская обл, г Октябрьск', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.1640488', geo_lon:  '48.670762' },
    { city: 'Отрадный', address: 'Самарская обл, г Отрадный', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.3800848', geo_lon:  '51.3438605' },
    { city: 'Похвистнево', address: 'Самарская обл, г Похвистнево', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.6498299', geo_lon:  '52.1235156' },
    { city: 'Самара', address: 'г Самара', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.1950306', geo_lon:  '50.1069518' },
    { city: 'Сызрань', address: 'Самарская обл, г Сызрань', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.1558674', geo_lon:  '48.4744629' },
    { city: 'Тольятти', address: 'Самарская обл, г Тольятти', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '53.5205348', geo_lon:  '49.3894028' },
    { city: 'Чапаевск', address: 'Самарская обл, г Чапаевск', federal_district: 'Приволжский', region: 'Самарская', timezone:  'UTC+4', geo_lat: '52.928961', geo_lon:  '49.8673269' },
    { city: '', address: 'г Санкт-Петербург', federal_district: 'Северо-Западный', region: 'Санкт-Петербург', timezone:  'UTC+3', geo_lat: '59.9391313', geo_lon:  '30.3159004' },
    { city: 'Аркадак', address: 'Саратовская обл, г Аркадак', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.938831', geo_lon:  '43.4999221' },
    { city: 'Аткарск', address: 'Саратовская обл, г Аткарск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.8736062', geo_lon:  '45.0003247' },
    { city: 'Балаково', address: 'Саратовская обл, г Балаково', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.0224231', geo_lon:  '47.7827765' },
    { city: 'Балашов', address: 'Саратовская обл, г Балашов', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.5388697', geo_lon:  '43.1839757' },
    { city: 'Вольск', address: 'Саратовская обл, г Вольск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.0459865', geo_lon:  '47.3873595' },
    { city: 'Ершов', address: 'Саратовская обл, г Ершов', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.3508505', geo_lon:  '48.2762226' },
    { city: 'Калининск', address: 'Саратовская обл, г Калининск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.4993591', geo_lon:  '44.4710435' },
    { city: 'Красноармейск', address: 'Саратовская обл, г Красноармейск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.023541', geo_lon:  '45.695044' },
    { city: 'Красный Кут', address: 'Саратовская обл, г Красный Кут', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '50.9598317', geo_lon:  '46.9712016' },
    { city: 'Маркс', address: 'Саратовская обл, г Маркс', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.7133337', geo_lon:  '46.7400339' },
    { city: 'Новоузенск', address: 'Саратовская обл, г Новоузенск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '50.455199', geo_lon:  '48.1413153' },
    { city: 'Петровск', address: 'Саратовская обл, г Петровск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.3094237', geo_lon:  '45.3851877' },
    { city: 'Пугачев', address: 'Саратовская обл, г Пугачев', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.0159921', geo_lon:  '48.7972223' },
    { city: 'Ртищево', address: 'Саратовская обл, г Ртищево', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.2616271', geo_lon:  '43.7842248' },
    { city: 'Саратов', address: 'г Саратов', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.5303047', geo_lon:  '45.9529348' },
    { city: 'Хвалынск', address: 'Саратовская обл, г Хвалынск', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.4951572', geo_lon:  '48.1045771' },
    { city: 'Шиханы', address: 'Саратовская обл, г Шиханы', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '52.1147391', geo_lon:  '47.2023118' },
    { city: 'Энгельс', address: 'Саратовская обл, г Энгельс', federal_district: 'Приволжский', region: 'Саратовская', timezone:  'UTC+4', geo_lat: '51.4854003', geo_lon:  '46.126722' },
    { city: 'Александровск-Сахалинский', address: 'Сахалинская обл, г Александровск-Сахалинский', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '50.8974378', geo_lon:  '142.1578559' },
    { city: 'Анива', address: 'Сахалинская обл, г Анива', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '46.713152', geo_lon:  '142.5265804' },
    { city: 'Долинск', address: 'Сахалинская обл, г Долинск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '47.3255783', geo_lon:  '142.7945071' },
    { city: 'Корсаков', address: 'Сахалинская обл, г Корсаков', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '46.6324545', geo_lon:  '142.799445' },
    { city: 'Курильск', address: 'Сахалинская обл, г Курильск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '45.2270954', geo_lon:  '147.8796323' },
    { city: 'Макаров', address: 'Сахалинская обл, г Макаров', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '48.6236334', geo_lon:  '142.7803205' },
    { city: 'Невельск', address: 'Сахалинская обл, г Невельск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '46.6526899', geo_lon:  '141.8630725' },
    { city: 'Оха', address: 'Сахалинская обл, г Оха', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '53.5867839', geo_lon:  '142.9412411' },
    { city: 'Поронайск', address: 'Сахалинская обл, г Поронайск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '49.238866', geo_lon:  '143.1008333' },
    { city: 'Северо-Курильск', address: 'Сахалинская обл, г Северо-Курильск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '50.6730577', geo_lon:  '156.1282211' },
    { city: 'Томари', address: 'Сахалинская обл, г Томари', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '47.7620108', geo_lon:  '142.0615837' },
    { city: 'Углегорск', address: 'Сахалинская обл, г Углегорск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '49.0815774', geo_lon:  '142.0692639' },
    { city: 'Холмск', address: 'Сахалинская обл, г Холмск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '47.0408423', geo_lon:  '142.041688' },
    { city: 'Южно-Сахалинск', address: 'г Южно-Сахалинск', federal_district: 'Дальневосточный', region: 'Сахалинская', timezone:  'UTC+11', geo_lat: '46.9591631', geo_lon:  '142.737976' },
    { city: 'Алапаевск', address: 'Свердловская обл, г Алапаевск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.8475571', geo_lon:  '61.6693817' },
    { city: 'Арамиль', address: 'Свердловская обл, Сысертский р-н, г Арамиль', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.694632', geo_lon:  '60.8343125' },
    { city: 'Артемовский', address: 'Свердловская обл, г Артемовский', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.3384177', geo_lon:  '61.8947443' },
    { city: 'Асбест', address: 'Свердловская обл, г Асбест', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.0052277', geo_lon:  '61.4581156' },
    { city: 'Березовский', address: 'Свердловская обл, г Березовский', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.9095924', geo_lon:  '60.8180907' },
    { city: 'Богданович', address: 'Свердловская обл, Богдановичский р-н, г Богданович', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.7764795', geo_lon:  '62.0463679' },
    { city: 'Верхний Тагил', address: 'Свердловская обл, г Верхний Тагил', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.3763758', geo_lon:  '59.9517653' },
    { city: 'Верхняя Пышма', address: 'Свердловская обл, г Верхняя Пышма', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.9758903', geo_lon:  '60.5650383' },
    { city: 'Верхняя Салда', address: 'Свердловская обл, г Верхняя Салда', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.0465803', geo_lon:  '60.5560164' },
    { city: 'Верхняя Тура', address: 'Свердловская обл, г Верхняя Тура', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.3643685', geo_lon:  '59.8265235' },
    { city: 'Верхотурье', address: 'Свердловская обл, г Верхотурье', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.8622073', geo_lon:  '60.8103945' },
    { city: 'Волчанск', address: 'Свердловская обл, г Волчанск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '59.9351707', geo_lon:  '60.0798618' },
    { city: 'Дегтярск', address: 'Свердловская обл, г Дегтярск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.7048206', geo_lon:  '60.079138' },
    { city: 'Екатеринбург', address: 'г Екатеринбург', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.8385216', geo_lon:  '60.6054911' },
    { city: 'Заречный', address: 'Свердловская обл, г Заречный', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.8102931', geo_lon:  '61.3380029' },
    { city: 'Ивдель', address: 'Свердловская обл, г Ивдель', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '60.6944496', geo_lon:  '60.4245069' },
    { city: 'Ирбит', address: 'Свердловская обл, г Ирбит', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.6838362', geo_lon:  '63.057675' },
    { city: 'Каменск-Уральский', address: 'Свердловская обл, г Каменск-Уральский', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.414962', geo_lon:  '61.9188674' },
    { city: 'Камышлов', address: 'Свердловская обл, г Камышлов', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.8465034', geo_lon:  '62.7119766' },
    { city: 'Карпинск', address: 'Свердловская обл, г Карпинск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '59.7665925', geo_lon:  '60.0011703' },
    { city: 'Качканар', address: 'Свердловская обл, г Качканар', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.7051762', geo_lon:  '59.4839155' },
    { city: 'Кировград', address: 'Свердловская обл, г Кировград', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.4299433', geo_lon:  '60.0624051' },
    { city: 'Краснотурьинск', address: 'Свердловская обл, г Краснотурьинск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '59.7636635', geo_lon:  '60.1934525' },
    { city: 'Красноуральск', address: 'Свердловская обл, г Красноуральск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.348651', geo_lon:  '60.0408764' },
    { city: 'Красноуфимск', address: 'Свердловская обл, г Красноуфимск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.612387', geo_lon:  '57.7636637' },
    { city: 'Кушва', address: 'Свердловская обл, г Кушва', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.2826013', geo_lon:  '59.7645766' },
    { city: 'Лесной', address: 'Свердловская обл, г Лесной', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.6348516', geo_lon:  '59.7981565' },
    { city: 'Михайловск', address: 'Свердловская обл, Нижнесергинский р-н, г Михайловск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.4370039', geo_lon:  '59.1137316' },
    { city: 'Невьянск', address: 'Свердловская обл, г Невьянск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.49131', geo_lon:  '60.2183429' },
    { city: 'Нижние Серги', address: 'Свердловская обл, г Нижние Серги', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.6544959', geo_lon:  '59.2953035' },
    { city: 'Нижний Тагил', address: 'Свердловская обл, г Нижний Тагил', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.910126', geo_lon:  '59.9812853' },
    { city: 'Нижняя Салда', address: 'Свердловская обл, г Нижняя Салда', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.0748272', geo_lon:  '60.7025418' },
    { city: 'Нижняя Тура', address: 'Свердловская обл, г Нижняя Тура', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.6309267', geo_lon:  '59.8520344' },
    { city: 'Новая Ляля', address: 'Свердловская обл, г Новая Ляля', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '59.0538977', geo_lon:  '60.5944825' },
    { city: 'Новоуральск', address: 'Свердловская обл, г Новоуральск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.2472567', geo_lon:  '60.0956714' },
    { city: 'Первоуральск', address: 'Свердловская обл, г Первоуральск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.9080085', geo_lon:  '59.942926' },
    { city: 'Полевской', address: 'Свердловская обл, г Полевской', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.4956952', geo_lon:  '60.2365298' },
    { city: 'Ревда', address: 'Свердловская обл, г Ревда', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.7986319', geo_lon:  '59.9071591' },
    { city: 'Реж', address: 'Свердловская обл, г Реж', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.3717477', geo_lon:  '61.3833842' },
    { city: 'Североуральск', address: 'Свердловская обл, г Североуральск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '60.1533109', geo_lon:  '59.9525245' },
    { city: 'Серов', address: 'Свердловская обл, г Серов', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '59.6047724', geo_lon:  '60.5753882' },
    { city: 'Среднеуральск', address: 'Свердловская обл, г Среднеуральск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.9918901', geo_lon:  '60.4771018' },
    { city: 'Сухой Лог', address: 'Свердловская обл, Сухоложский р-н, г Сухой Лог', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.9076193', geo_lon:  '62.0358093' },
    { city: 'Сысерть', address: 'Свердловская обл, г Сысерть', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '56.5005715', geo_lon:  '60.8190003' },
    { city: 'Тавда', address: 'Свердловская обл, г Тавда', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.0434672', geo_lon:  '65.274217' },
    { city: 'Талица', address: 'Свердловская обл, г Талица', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '57.0122687', geo_lon:  '63.7320757' },
    { city: 'Туринск', address: 'Свердловская обл, г Туринск', federal_district: 'Уральский', region: 'Свердловская', timezone:  'UTC+5', geo_lat: '58.0393524', geo_lon:  '63.6981973' },
    { city: '', address: 'г Севастополь', federal_district: 'Южный', region: 'Севастополь', timezone:  'UTC+3', geo_lat: '44.6167013', geo_lon:  '33.525355' },
    { city: 'Алагир', address: 'Респ Северная Осетия - Алания, г Алагир', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.0417684', geo_lon:  '44.2199715' },
    { city: 'Ардон', address: 'Респ Северная Осетия - Алания, г Ардон', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.1755152', geo_lon:  '44.2955775' },
    { city: 'Беслан', address: 'Респ Северная Осетия - Алания, г Беслан', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.1937529', geo_lon:  '44.5338707' },
    { city: 'Владикавказ', address: 'г Владикавказ', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.020588', geo_lon:  '44.6819182' },
    { city: 'Дигора', address: 'Респ Северная Осетия - Алания, г Дигора', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.1567628', geo_lon:  '44.1549483' },
    { city: 'Моздок', address: 'Респ Северная Осетия - Алания, г Моздок', federal_district: 'Северо-Кавказский', region: 'Северная Осетия - Алания', timezone:  'UTC+3', geo_lat: '43.7471342', geo_lon:  '44.6569607' },
    { city: 'Велиж', address: 'Смоленская обл, г Велиж', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.6057916', geo_lon:  '31.1856206' },
    { city: 'Вязьма', address: 'Смоленская обл, г Вязьма', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.2116983', geo_lon:  '34.2951663' },
    { city: 'Гагарин', address: 'Смоленская обл, г Гагарин', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.5525228', geo_lon:  '34.9950502' },
    { city: 'Демидов', address: 'Смоленская обл, г Демидов', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.2682105', geo_lon:  '31.5062809' },
    { city: 'Десногорск', address: 'Смоленская обл, г Десногорск', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.146457', geo_lon:  '33.2833222' },
    { city: 'Дорогобуж', address: 'Смоленская обл, г Дорогобуж', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.9136959', geo_lon:  '33.3023162' },
    { city: 'Духовщина', address: 'Смоленская обл, г Духовщина', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.1950257', geo_lon:  '32.401252' },
    { city: 'Ельня', address: 'Смоленская обл, г Ельня', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.5837795', geo_lon:  '33.1749867' },
    { city: 'Починок', address: 'Смоленская обл, г Починок', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.406244', geo_lon:  '32.4398039' },
    { city: 'Рославль', address: 'Смоленская обл, г Рославль', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '53.9449558', geo_lon:  '32.8480258' },
    { city: 'Рудня', address: 'Смоленская обл, г Рудня', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.9441093', geo_lon:  '31.0794806' },
    { city: 'Сафоново', address: 'Смоленская обл, г Сафоново', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.1199661', geo_lon:  '33.2336988' },
    { city: 'Смоленск', address: 'г Смоленск', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '54.7866913', geo_lon:  '31.8152477' },
    { city: 'Сычевка', address: 'Смоленская обл, г Сычевка', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.8308821', geo_lon:  '34.2778793' },
    { city: 'Ярцево', address: 'Смоленская обл, г Ярцево', federal_district: 'Центральный', region: 'Смоленская', timezone:  'UTC+3', geo_lat: '55.0564732', geo_lon:  '32.6902302' },
    { city: 'Благодарный', address: 'Ставропольский край, г Благодарный', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.0989782', geo_lon:  '43.4306455' },
    { city: 'Буденновск', address: 'Ставропольский край, г Буденновск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.7816067', geo_lon:  '44.1650339' },
    { city: 'Георгиевск', address: 'Ставропольский край, г Георгиевск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.1485694', geo_lon:  '43.4739851' },
    { city: 'Ессентуки', address: 'Ставропольский край, г Ессентуки', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.0446186', geo_lon:  '42.8588653' },
    { city: 'Железноводск', address: 'Ставропольский край, г Железноводск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.1320568', geo_lon:  '43.0306461' },
    { city: 'Зеленокумск', address: 'Ставропольский край, г Зеленокумск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.4032668', geo_lon:  '43.8841877' },
    { city: 'Изобильный', address: 'Ставропольский край, г Изобильный', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.3684296', geo_lon:  '41.708702' },
    { city: 'Ипатово', address: 'Ставропольский край, г Ипатово', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.7181751', geo_lon:  '42.8970206' },
    { city: 'Кисловодск', address: 'Ставропольский край, г Кисловодск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '43.9052044', geo_lon:  '42.7168721' },
    { city: 'Лермонтов', address: 'Ставропольский край, г Лермонтов', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.1054107', geo_lon:  '42.973135' },
    { city: 'Минеральные Воды', address: 'Ставропольский край, г Минеральные Воды', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.2087273', geo_lon:  '43.138408' },
    { city: 'Михайловск', address: 'Ставропольский край, г Михайловск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.1297323', geo_lon:  '42.0288443' },
    { city: 'Невинномысск', address: 'Ставропольский край, г Невинномысск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.6226031', geo_lon:  '41.9476723' },
    { city: 'Нефтекумск', address: 'Ставропольский край, г Нефтекумск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.7544552', geo_lon:  '44.9865347' },
    { city: 'Новоалександровск', address: 'Ставропольский край, г Новоалександровск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.4932733', geo_lon:  '41.2153996' },
    { city: 'Новопавловск', address: 'Ставропольский край, г Новопавловск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '43.9617097', geo_lon:  '43.6342865' },
    { city: 'Пятигорск', address: 'Ставропольский край, г Пятигорск', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '44.041091', geo_lon:  '43.0661553' },
    { city: 'Светлоград', address: 'Ставропольский край, г Светлоград', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.328659', geo_lon:  '42.8565714' },
    { city: 'Ставрополь', address: 'г Ставрополь', federal_district: 'Северо-Кавказский', region: 'Ставропольский', timezone:  'UTC+3', geo_lat: '45.044516', geo_lon:  '41.9689655' },
    { city: 'Жердевка', address: 'Тамбовская обл, г Жердевка', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '51.8422192', geo_lon:  '41.4617687' },
    { city: 'Кирсанов', address: 'Тамбовская обл, г Кирсанов', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '52.6506335', geo_lon:  '42.7286445' },
    { city: 'Котовск', address: 'Тамбовская обл, г Котовск', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '52.5924489', geo_lon:  '41.5101237' },
    { city: 'Мичуринск', address: 'Тамбовская обл, г Мичуринск', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '52.8912389', geo_lon:  '40.5104443' },
    { city: 'Моршанск', address: 'Тамбовская обл, г Моршанск', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '53.4436216', geo_lon:  '41.8115478' },
    { city: 'Рассказово', address: 'Тамбовская обл, г Рассказово', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '52.6538833', geo_lon:  '41.874285' },
    { city: 'Тамбов', address: 'г Тамбов', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '52.7213154', geo_lon:  '41.452264' },
    { city: 'Уварово', address: 'Тамбовская обл, г Уварово', federal_district: 'Центральный', region: 'Тамбовская', timezone:  'UTC+3', geo_lat: '51.9767841', geo_lon:  '42.2529799' },
    { city: 'Агрыз', address: 'Респ Татарстан, г Агрыз', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '56.5232864', geo_lon:  '52.9943775' },
    { city: 'Азнакаево', address: 'Респ Татарстан, г Азнакаево', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.8598642', geo_lon:  '53.0745527' },
    { city: 'Альметьевск', address: 'Респ Татарстан, г Альметьевск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.9014619', geo_lon:  '52.2970467' },
    { city: 'Арск', address: 'Респ Татарстан, г Арск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '56.0912567', geo_lon:  '49.877067' },
    { city: 'Бавлы', address: 'Респ Татарстан, г Бавлы', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.4062891', geo_lon:  '53.2458065' },
    { city: 'Болгар', address: 'Респ Татарстан, г Болгар', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.974891', geo_lon:  '49.0303882' },
    { city: 'Бугульма', address: 'Респ Татарстан, г Бугульма', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.5363495', geo_lon:  '52.7895849' },
    { city: 'Буинск', address: 'Респ Татарстан, г Буинск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.9641538', geo_lon:  '48.2901209' },
    { city: 'Елабуга', address: 'Респ Татарстан, г Елабуга', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7567107', geo_lon:  '52.0543794' },
    { city: 'Заинск', address: 'Респ Татарстан, г Заинск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.299053', geo_lon:  '52.0062972' },
    { city: 'Зеленодольск', address: 'Респ Татарстан, г Зеленодольск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.8466651', geo_lon:  '48.5010954' },
    { city: 'Иннополис', address: 'Респ Татарстан, Верхнеуслонский р-н, г Иннополис', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7521699', geo_lon:  '48.7446846' },
    { city: 'Казань', address: 'г Казань', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7943584', geo_lon:  '49.1114975' },
    { city: 'Кукмор', address: 'Респ Татарстан, г Кукмор', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '56.1861392', geo_lon:  '50.8970238' },
    { city: 'Лаишево', address: 'Респ Татарстан, г Лаишево', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.4042867', geo_lon:  '49.5499838' },
    { city: 'Лениногорск', address: 'Респ Татарстан, г Лениногорск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.5967034', geo_lon:  '52.4431906' },
    { city: 'Мамадыш', address: 'Респ Татарстан, г Мамадыш', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7150413', geo_lon:  '51.4129016' },
    { city: 'Менделеевск', address: 'Респ Татарстан, г Менделеевск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.895169', geo_lon:  '52.3143347' },
    { city: 'Мензелинск', address: 'Респ Татарстан, г Мензелинск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7270698', geo_lon:  '53.1003968' },
    { city: 'Набережные Челны', address: 'Респ Татарстан, г Набережные Челны', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.7434619', geo_lon:  '52.3959165' },
    { city: 'Нижнекамск', address: 'Респ Татарстан, г Нижнекамск', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.6313609', geo_lon:  '51.8144669' },
    { city: 'Нурлат', address: 'Респ Татарстан, г Нурлат', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.4281461', geo_lon:  '50.8049337' },
    { city: 'Тетюши', address: 'Респ Татарстан, г Тетюши', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '54.936516', geo_lon:  '48.8314533' },
    { city: 'Чистополь', address: 'Респ Татарстан, г Чистополь', federal_district: 'Приволжский', region: 'Татарстан', timezone:  'UTC+3', geo_lat: '55.3699139', geo_lon:  '50.6285784' },
    { city: 'Андреаполь', address: 'Тверская обл, г Андреаполь', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.6506724', geo_lon:  '32.2620163' },
    { city: 'Бежецк', address: 'Тверская обл, г Бежецк', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.7860089', geo_lon:  '36.6904983' },
    { city: 'Белый', address: 'Тверская обл, г Белый', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '55.8339056', geo_lon:  '32.9389741' },
    { city: 'Бологое', address: 'Тверская обл, г Бологое', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.8855767', geo_lon:  '34.0537771' },
    { city: 'Весьегонск', address: 'Тверская обл, г Весьегонск', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '58.6582598', geo_lon:  '37.2567558' },
    { city: 'Вышний Волочек', address: 'Тверская обл, г Вышний Волочек', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.568302', geo_lon:  '34.5404016' },
    { city: 'Западная Двина', address: 'Тверская обл, г Западная Двина', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.2566492', geo_lon:  '32.0805315' },
    { city: 'Зубцов', address: 'Тверская обл, г Зубцов', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.1760868', geo_lon:  '34.5825515' },
    { city: 'Калязин', address: 'Тверская обл, г Калязин', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.2579478', geo_lon:  '37.7819693' },
    { city: 'Кашин', address: 'Тверская обл, г Кашин', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.360194', geo_lon:  '37.6119436' },
    { city: 'Кимры', address: 'Тверская обл, г Кимры', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.8733213', geo_lon:  '37.3556605' },
    { city: 'Конаково', address: 'Тверская обл, г Конаково', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.7275204', geo_lon:  '36.8012716' },
    { city: 'Красный Холм', address: 'Тверская обл, г Красный Холм', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '58.0571446', geo_lon:  '37.1126156' },
    { city: 'Кувшиново', address: 'Тверская обл, г Кувшиново', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.0265168', geo_lon:  '34.1676009' },
    { city: 'Лихославль', address: 'Тверская обл, г Лихославль', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.1221304', geo_lon:  '35.4667605' },
    { city: 'Нелидово', address: 'Тверская обл, г Нелидово', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.2232566', geo_lon:  '32.7767459' },
    { city: 'Осташков', address: 'Тверская обл, г Осташков', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.1456744', geo_lon:  '33.1115372' },
    { city: 'Ржев', address: 'Тверская обл, г Ржев', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.262881', geo_lon:  '34.3291002' },
    { city: 'Старица', address: 'Тверская обл, г Старица', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.514876', geo_lon:  '34.9336396' },
    { city: 'Тверь', address: 'г Тверь', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.8586059', geo_lon:  '35.9116761' },
    { city: 'Торжок', address: 'Тверская обл, г Торжок', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.04133', geo_lon:  '34.9602344' },
    { city: 'Торопец', address: 'Тверская обл, г Торопец', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '56.5012188', geo_lon:  '31.6355466' },
    { city: 'Удомля', address: 'Тверская обл, г Удомля', federal_district: 'Центральный', region: 'Тверская', timezone:  'UTC+3', geo_lat: '57.8787314', geo_lon:  '35.0167348' },
    { city: 'Асино', address: 'Томская обл, г Асино', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '56.9907085', geo_lon:  '86.1765257' },
    { city: 'Кедровый', address: 'Томская обл, г Кедровый', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '57.561869', geo_lon:  '79.5677821' },
    { city: 'Колпашево', address: 'Томская обл, г Колпашево', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '58.3114253', geo_lon:  '82.9025829' },
    { city: 'Северск', address: 'Томская обл, г Северск', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '56.6031285', geo_lon:  '84.8809926' },
    { city: 'Стрежевой', address: 'Томская обл, г Стрежевой', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '60.732895', geo_lon:  '77.604122' },
    { city: 'Томск', address: 'г Томск', federal_district: 'Сибирский', region: 'Томская', timezone:  'UTC+7', geo_lat: '56.4845804', geo_lon:  '84.9481582' },
    { city: 'Алексин', address: 'Тульская обл, г Алексин', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.5083349', geo_lon:  '37.0478067' },
    { city: 'Белев', address: 'Тульская обл, г Белев', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.8114179', geo_lon:  '36.1382247' },
    { city: 'Богородицк', address: 'Тульская обл, г Богородицк', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.7701014', geo_lon:  '38.1225152' },
    { city: 'Болохово', address: 'Тульская обл, Киреевский р-н, г Болохово', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.0820349', geo_lon:  '37.826724' },
    { city: 'Венев', address: 'Тульская обл, г Венев', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.3542315', geo_lon:  '38.2642236' },
    { city: 'Донской', address: 'Тульская обл, г Донской', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9678944', geo_lon:  '38.3371824' },
    { city: 'Ефремов', address: 'Тульская обл, г Ефремов', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.1464766', geo_lon:  '38.0921657' },
    { city: 'Кимовск', address: 'Тульская обл, г Кимовск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9698378', geo_lon:  '38.5380808' },
    { city: 'Киреевск', address: 'Тульская обл, г Киреевск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9319555', geo_lon:  '37.9220351' },
    { city: 'Липки', address: 'Тульская обл, Киреевский р-н, г Липки', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9417551', geo_lon:  '37.7020148' },
    { city: 'Новомосковск', address: 'Тульская обл, г Новомосковск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.0109075', geo_lon:  '38.2914024' },
    { city: 'Плавск', address: 'Тульская обл, г Плавск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.7096415', geo_lon:  '37.2862352' },
    { city: 'Советск', address: 'Тульская обл, Щекинский р-н, г Советск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9338874', geo_lon:  '37.6316141' },
    { city: 'Суворов', address: 'Тульская обл, г Суворов', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.1343585', geo_lon:  '36.4807419' },
    { city: 'Тула', address: 'г Тула', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.1920559', geo_lon:  '37.6153842' },
    { city: 'Узловая', address: 'Тульская обл, г Узловая', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '53.9730452', geo_lon:  '38.1763201' },
    { city: 'Чекалин', address: 'Тульская обл, Суворовский р-н, г Чекалин', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.0984438', geo_lon:  '36.2474142' },
    { city: 'Щекино', address: 'Тульская обл, г Щекино', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.0020652', geo_lon:  '37.5176288' },
    { city: 'Ясногорск', address: 'Тульская обл, г Ясногорск', federal_district: 'Центральный', region: 'Тульская', timezone:  'UTC+3', geo_lat: '54.4795484', geo_lon:  '37.6896048' },
    { city: 'Ак-Довурак', address: 'Респ Тыва, г Ак-Довурак', federal_district: 'Сибирский', region: 'Тыва', timezone:  'UTC+7', geo_lat: '51.178452', geo_lon:  '90.5985129' },
    { city: 'Кызыл', address: 'г Кызыл', federal_district: 'Сибирский', region: 'Тыва', timezone:  'UTC+7', geo_lat: '51.7191047', geo_lon:  '94.4376882' },
    { city: 'Туран', address: 'Респ Тыва, г Туран', federal_district: 'Сибирский', region: 'Тыва', timezone:  'UTC+7', geo_lat: '52.1449619', geo_lon:  '93.9173396' },
    { city: 'Чадан', address: 'Респ Тыва, г Чадан', federal_district: 'Сибирский', region: 'Тыва', timezone:  'UTC+7', geo_lat: '51.2844502', geo_lon:  '91.5788609' },
    { city: 'Шагонар', address: 'Респ Тыва, г Шагонар', federal_district: 'Сибирский', region: 'Тыва', timezone:  'UTC+7', geo_lat: '51.5346393', geo_lon:  '92.9199675' },
    { city: 'Заводоуковск', address: 'Тюменская обл, г Заводоуковск', federal_district: 'Уральский', region: 'Тюменская', timezone:  'UTC+5', geo_lat: '56.5027463', geo_lon:  '66.5513613' },
    { city: 'Ишим', address: 'Тюменская обл, г Ишим', federal_district: 'Уральский', region: 'Тюменская', timezone:  'UTC+5', geo_lat: '56.1104858', geo_lon:  '69.4795776' },
    { city: 'Тобольск', address: 'Тюменская обл, г Тобольск', federal_district: 'Уральский', region: 'Тюменская', timezone:  'UTC+5', geo_lat: '58.2017299', geo_lon:  '68.2538558' },
    { city: 'Тюмень', address: 'Тюменская обл, г Тюмень', federal_district: 'Уральский', region: 'Тюменская', timezone:  'UTC+5', geo_lat: '57.1529744', geo_lon:  '65.5344099' },
    { city: 'Ялуторовск', address: 'Тюменская обл, г Ялуторовск', federal_district: 'Уральский', region: 'Тюменская', timezone:  'UTC+5', geo_lat: '56.6547289', geo_lon:  '66.3122992' },
    { city: 'Воткинск', address: 'Респ Удмуртская, г Воткинск', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '57.0518149', geo_lon:  '53.9873096' },
    { city: 'Глазов', address: 'Респ Удмуртская, г Глазов', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '58.1359233', geo_lon:  '52.6635038' },
    { city: 'Ижевск', address: 'г Ижевск', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '56.852738', geo_lon:  '53.2114896' },
    { city: 'Камбарка', address: 'Респ Удмуртская, г Камбарка', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '56.2659916', geo_lon:  '54.193374' },
    { city: 'Можга', address: 'Респ Удмуртская, г Можга', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '56.4427774', geo_lon:  '52.2137886' },
    { city: 'Сарапул', address: 'Респ Удмуртская, г Сарапул', federal_district: 'Приволжский', region: 'Удмуртская', timezone:  'UTC+4', geo_lat: '56.4615767', geo_lon:  '53.8037657' },
    { city: 'Барыш', address: 'Ульяновская обл, г Барыш', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '53.6533992', geo_lon:  '47.1181134' },
    { city: 'Димитровград', address: 'Ульяновская обл, г Димитровград', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '54.2167926', geo_lon:  '49.6262585' },
    { city: 'Инза', address: 'Ульяновская обл, г Инза', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '53.8549647', geo_lon:  '46.3533459' },
    { city: 'Новоульяновск', address: 'Ульяновская обл, г Новоульяновск', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '54.1447956', geo_lon:  '48.3910789' },
    { city: 'Сенгилей', address: 'Ульяновская обл, г Сенгилей', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '53.958964', geo_lon:  '48.7768269' },
    { city: 'Ульяновск', address: 'г Ульяновск', federal_district: 'Приволжский', region: 'Ульяновская', timezone:  'UTC+4', geo_lat: '54.3079415', geo_lon:  '48.3748487' },
    { city: 'Амурск', address: 'Хабаровский край, г Амурск', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '50.2344147', geo_lon:  '136.8792444' },
    { city: 'Бикин', address: 'Хабаровский край, г Бикин', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '46.8185743', geo_lon:  '134.2550718' },
    { city: 'Вяземский', address: 'Хабаровский край, г Вяземский', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '47.5353379', geo_lon:  '134.7553856' },
    { city: 'Комсомольск-на-Амуре', address: 'Хабаровский край, г Комсомольск-на-Амуре', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '50.5498936', geo_lon:  '137.0079408' },
    { city: 'Николаевск-на-Амуре', address: 'Хабаровский край, г Николаевск-на-Амуре', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '53.1460657', geo_lon:  '140.7111367' },
    { city: 'Советская Гавань', address: 'Хабаровский край, г Советская Гавань', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '48.9664966', geo_lon:  '140.285174' },
    { city: 'Хабаровск', address: 'г Хабаровск', federal_district: 'Дальневосточный', region: 'Хабаровский', timezone:  'UTC+10', geo_lat: '48.4647258', geo_lon:  '135.0598942' },
    { city: 'Абаза', address: 'Респ Хакасия, г Абаза', federal_district: 'Сибирский', region: 'Хакасия', timezone:  'UTC+7', geo_lat: '52.6516647', geo_lon:  '90.0885686' },
    { city: 'Абакан', address: 'г Абакан', federal_district: 'Сибирский', region: 'Хакасия', timezone:  'UTC+7', geo_lat: '53.7223325', geo_lon:  '91.4436721' },
    { city: 'Саяногорск', address: 'Респ Хакасия, г Саяногорск', federal_district: 'Сибирский', region: 'Хакасия', timezone:  'UTC+7', geo_lat: '53.1008083', geo_lon:  '91.4122454' },
    { city: 'Сорск', address: 'Респ Хакасия, г Сорск', federal_district: 'Сибирский', region: 'Хакасия', timezone:  'UTC+7', geo_lat: '54.0002888', geo_lon:  '90.2594446' },
    { city: 'Черногорск', address: 'Респ Хакасия, г Черногорск', federal_district: 'Сибирский', region: 'Хакасия', timezone:  'UTC+7', geo_lat: '53.8259342', geo_lon:  '91.3260229' },
    { city: 'Белоярский', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Белоярский', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '63.7121099', geo_lon:  '66.6772226' },
    { city: 'Когалым', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Когалым', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '62.2639527', geo_lon:  '74.4829794' },
    { city: 'Лангепас', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Лангепас', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.2536939', geo_lon:  '75.1807763' },
    { city: 'Лянтор', address: 'Ханты-Мансийский Автономный округ - Югра АО, Сургутский р-н, г Лянтор', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.6392863', geo_lon:  '72.179409' },
    { city: 'Мегион', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Мегион', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.0318712', geo_lon:  '76.1025878' },
    { city: 'Нефтеюганск', address: 'Ханты-Мансийский Автономный округ - Югра, г Нефтеюганск', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.0882676', geo_lon:  '72.6164079' },
    { city: 'Нижневартовск', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Нижневартовск', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '60.9396698', geo_lon:  '76.5696184' },
    { city: 'Нягань', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Нягань', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '62.1454701', geo_lon:  '65.3946047' },
    { city: 'Покачи', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Покачи', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.7422169', geo_lon:  '75.5941517' },
    { city: 'Пыть-Ях', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Пыть-Ях', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '60.7585833', geo_lon:  '72.8365617' },
    { city: 'Радужный', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Радужный', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '62.1342888', geo_lon:  '77.4584094' },
    { city: 'Советский', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Советский', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.3706913', geo_lon:  '63.5667222' },
    { city: 'Сургут', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Сургут', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.2541083', geo_lon:  '73.3961587' },
    { city: 'Урай', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Урай', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '60.1296954', geo_lon:  '64.8038508' },
    { city: 'Ханты-Мансийск', address: 'г Ханты-Мансийск', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.0023984', geo_lon:  '69.0184798' },
    { city: 'Югорск', address: 'Ханты-Мансийский Автономный округ - Югра АО, г Югорск', federal_district: 'Уральский', region: 'Ханты-Мансийский Автономный округ - Югра', timezone:  'UTC+5', geo_lat: '61.3123568', geo_lon:  '63.3365484' },
    { city: 'Аша', address: 'Челябинская обл, г Аша', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.9906527', geo_lon:  '57.2783953' },
    { city: 'Бакал', address: 'Челябинская обл, Саткинский р-н, г Бакал', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.9406399', geo_lon:  '58.8051698' },
    { city: 'Верхнеуральск', address: 'Челябинская обл, г Верхнеуральск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '53.8760961', geo_lon:  '59.2169852' },
    { city: 'Верхний Уфалей', address: 'Челябинская обл, г Верхний Уфалей', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '56.0487158', geo_lon:  '60.2318886' },
    { city: 'Еманжелинск', address: 'Челябинская обл, г Еманжелинск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.7554548', geo_lon:  '61.3243477' },
    { city: 'Златоуст', address: 'Челябинская обл, г Златоуст', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.1714905', geo_lon:  '59.6725549' },
    { city: 'Карабаш', address: 'Челябинская обл, г Карабаш', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.4852323', geo_lon:  '60.2358881' },
    { city: 'Карталы', address: 'Челябинская обл, г Карталы', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '53.0536197', geo_lon:  '60.6478408' },
    { city: 'Касли', address: 'Челябинская обл, г Касли', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.8868784', geo_lon:  '60.7421663' },
    { city: 'Катав-Ивановск', address: 'Челябинская обл, г Катав-Ивановск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.7521438', geo_lon:  '58.1983648' },
    { city: 'Копейск', address: 'Челябинская обл, г Копейск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.116665', geo_lon:  '61.6179185' },
    { city: 'Коркино', address: 'Челябинская обл, г Коркино', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.8903147', geo_lon:  '61.4034576' },
    { city: 'Куса', address: 'Челябинская обл, г Куса', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.3386053', geo_lon:  '59.4385778' },
    { city: 'Кыштым', address: 'Челябинская обл, г Кыштым', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.7061276', geo_lon:  '60.5563781' },
    { city: 'Магнитогорск', address: 'Челябинская обл, г Магнитогорск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '53.4072153', geo_lon:  '58.9791437' },
    { city: 'Миасс', address: 'Челябинская обл, г Миасс', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.0456457', geo_lon:  '60.1077572' },
    { city: 'Миньяр', address: 'Челябинская обл, Ашинский р-н, г Миньяр', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.0709557', geo_lon:  '57.548478' },
    { city: 'Нязепетровск', address: 'Челябинская обл, г Нязепетровск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '56.0536895', geo_lon:  '59.6097202' },
    { city: 'Озерск', address: 'Челябинская обл, г Озерск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.763154', geo_lon:  '60.7076198' },
    { city: 'Пласт', address: 'Челябинская обл, г Пласт', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.3692764', geo_lon:  '60.8151894' },
    { city: 'Сатка', address: 'Челябинская обл, г Сатка', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.0405288', geo_lon:  '59.0288975' },
    { city: 'Сим', address: 'Челябинская обл, Ашинский р-н, г Сим', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.9907827', geo_lon:  '57.6900155' },
    { city: 'Снежинск', address: 'Челябинская обл, г Снежинск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '56.0851495', geo_lon:  '60.7324914' },
    { city: 'Трехгорный', address: 'Челябинская обл, г Трехгорный', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.8178249', geo_lon:  '58.4464194' },
    { city: 'Троицк', address: 'Челябинская обл, г Троицк', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.0843745', geo_lon:  '61.5586831' },
    { city: 'Усть-Катав', address: 'Челябинская обл, г Усть-Катав', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.9260812', geo_lon:  '58.152805' },
    { city: 'Чебаркуль', address: 'Челябинская обл, г Чебаркуль', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.9818567', geo_lon:  '60.3773121' },
    { city: 'Челябинск', address: 'г Челябинск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '55.1602624', geo_lon:  '61.4008078' },
    { city: 'Южноуральск', address: 'Челябинская обл, г Южноуральск', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.448927', geo_lon:  '61.2581158' },
    { city: 'Юрюзань', address: 'Челябинская обл, Катав-Ивановский р-н, г Юрюзань', federal_district: 'Уральский', region: 'Челябинская', timezone:  'UTC+5', geo_lat: '54.854662', geo_lon:  '58.4226698' },
    { city: 'Аргун', address: 'Респ Чеченская, г Аргун', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.2916774', geo_lon:  '45.8723105' },
    { city: 'Грозный', address: 'г Грозный', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.3180145', geo_lon:  '45.698291' },
    { city: 'Гудермес', address: 'Респ Чеченская, г Гудермес', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.3519142', geo_lon:  '46.1035645' },
    { city: 'Курчалой', address: 'Респ Чеченская, г Курчалой', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.2046547', geo_lon:  '46.0889364' },
    { city: '', address: 'Респ Чеченская, г Урус-Мартан', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.1201923', geo_lon:  '45.5391777' },
    { city: 'Шали', address: 'Респ Чеченская, г Шали', federal_district: 'Северо-Кавказский', region: 'Чеченская', timezone:  'UTC+3', geo_lat: '43.1488691', geo_lon:  '45.9009629' },
    { city: 'Алатырь', address: 'Чувашская Республика - Чувашия, г Алатырь', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '54.8397989', geo_lon:  '46.5721997' },
    { city: 'Канаш', address: 'Чувашская Республика - Чувашия, г Канаш', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '55.507', geo_lon:  '47.4918273' },
    { city: 'Козловка', address: 'Чувашская Республика - Чувашия, г Козловка', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '55.8406025', geo_lon:  '48.2577735' },
    { city: 'Мариинский Посад', address: 'Чувашская Республика - Чувашия, г Мариинский Посад', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '56.111923', geo_lon:  '47.7142942' },
    { city: 'Новочебоксарск', address: 'Чувашская Республика - Чувашия, г Новочебоксарск', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '56.1094977', geo_lon:  '47.4791113' },
    { city: 'Цивильск', address: 'Чувашская Республика - Чувашия, г Цивильск', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '55.8650213', geo_lon:  '47.4729349' },
    { city: 'Чебоксары', address: 'г Чебоксары', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '56.1438298', geo_lon:  '47.2489782' },
    { city: 'Шумерля', address: 'Чувашская Республика - Чувашия, г Шумерля', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '55.4962415', geo_lon:  '46.4182681' },
    { city: 'Ядрин', address: 'Чувашская Республика - Чувашия, г Ядрин', federal_district: 'Приволжский', region: 'Чувашская Республика', timezone:  'UTC+3', geo_lat: '55.9406974', geo_lon:  '46.2020896' },
    { city: 'Анадырь', address: 'г Анадырь', federal_district: 'Дальневосточный', region: 'Чукотский', timezone:  'UTC+12', geo_lat: '64.7313924', geo_lon:  '177.5015421' },
    { city: 'Билибино', address: 'Чукотский АО, г Билибино', federal_district: 'Дальневосточный', region: 'Чукотский', timezone:  'UTC+12', geo_lat: '68.0584191', geo_lon:  '166.4388172' },
    { city: 'Певек', address: 'Чукотский АО, г Певек', federal_district: 'Дальневосточный', region: 'Чукотский', timezone:  'UTC+12', geo_lat: '69.7016661', geo_lon:  '170.2999022' },
    { city: 'Алдан', address: 'Респ Саха /Якутия/, г Алдан', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '58.6094283', geo_lon:  '125.3817188' },
    { city: 'Верхоянск', address: 'Респ Саха /Якутия/, у Верхоянский, г Верхоянск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+10', geo_lat: '67.5502451', geo_lon:  '133.390735' },
    { city: 'Вилюйск', address: 'Респ Саха /Якутия/, г Вилюйск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '63.7517616', geo_lon:  '121.627284' },
    { city: 'Ленск', address: 'Респ Саха /Якутия/, г Ленск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '60.7276196', geo_lon:  '114.9548255' },
    { city: 'Мирный', address: 'Респ Саха /Якутия/, г Мирный', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '62.536232', geo_lon:  '113.9667728' },
    { city: 'Нерюнгри', address: 'Респ Саха /Якутия/, г Нерюнгри', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '56.6599953', geo_lon:  '124.7202403' },
    { city: 'Нюрба', address: 'Респ Саха /Якутия/, г Нюрба', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '63.2828955', geo_lon:  '118.3242437' },
    { city: 'Олекминск', address: 'Респ Саха /Якутия/, г Олекминск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '60.3758006', geo_lon:  '120.4060878' },
    { city: 'Покровск', address: 'Респ Саха /Якутия/, г Покровск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '61.4843503', geo_lon:  '129.1482392' },
    { city: 'Среднеколымск', address: 'Респ Саха /Якутия/, г Среднеколымск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+11', geo_lat: '67.4582218', geo_lon:  '153.7069425' },
    { city: 'Томмот', address: 'Респ Саха /Якутия/, у Алданский, г Томмот', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '58.9586859', geo_lon:  '126.2875462' },
    { city: 'Удачный', address: 'Респ Саха /Якутия/, у Мирнинский, г Удачный', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '66.4071765', geo_lon:  '112.3061555' },
    { city: 'Якутск', address: 'г Якутск', federal_district: 'Дальневосточный', region: 'Саха /Якутия/', timezone:  'UTC+9', geo_lat: '62.0281405', geo_lon:  '129.7325887' },
    { city: 'Губкинский', address: 'Ямало-Ненецкий АО, г Губкинский', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '64.4457594', geo_lon:  '76.4713274' },
    { city: 'Лабытнанги', address: 'Ямало-Ненецкий АО, г Лабытнанги', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '66.6592841', geo_lon:  '66.3883009' },
    { city: 'Муравленко', address: 'Ямало-Ненецкий АО, г Муравленко', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '63.7940552', geo_lon:  '74.4948635' },
    { city: 'Надым', address: 'Ямало-Ненецкий АО, г Надым', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '65.5377966', geo_lon:  '72.5182736' },
    { city: 'Новый Уренгой', address: 'Ямало-Ненецкий АО, г Новый Уренгой', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '66.0839433', geo_lon:  '76.6809681' },
    { city: 'Ноябрьск', address: 'Ямало-Ненецкий АО, г Ноябрьск', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '63.2018039', geo_lon:  '75.4510581' },
    { city: 'Салехард', address: 'г Салехард', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '66.5492077', geo_lon:  '66.6085318' },
    { city: 'Тарко-Сале', address: 'Ямало-Ненецкий АО, г Тарко-Сале', federal_district: 'Уральский', region: 'Ямало-Ненецкий', timezone:  'UTC+5', geo_lat: '64.9118803', geo_lon:  '77.7610236' },
    { city: 'Гаврилов-Ям', address: 'Ярославская обл, г Гаврилов-Ям', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.3091058', geo_lon:  '39.8546444' },
    { city: 'Данилов', address: 'Ярославская обл, г Данилов', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '58.1860098', geo_lon:  '40.1795067' },
    { city: 'Любим', address: 'Ярославская обл, г Любим', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '58.3620228', geo_lon:  '40.686841' },
    { city: 'Мышкин', address: 'Ярославская обл, г Мышкин', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.7881684', geo_lon:  '38.4544224' },
    { city: 'Переславль-Залесский', address: 'Ярославская обл, г Переславль-Залесский', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '56.7360544', geo_lon:  '38.8543617' },
    { city: 'Пошехонье', address: 'Ярославская обл, г Пошехонье', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '58.5062879', geo_lon:  '39.1208434' },
    { city: 'Ростов', address: 'Ярославская обл, г Ростов', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.2051315', geo_lon:  '39.4378622' },
    { city: 'Рыбинск', address: 'Ярославская обл, г Рыбинск', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '58.0485495', geo_lon:  '38.8584119' },
    { city: 'Тутаев', address: 'Ярославская обл, г Тутаев', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.8674993', geo_lon:  '39.5369627' },
    { city: 'Углич', address: 'Ярославская обл, г Углич', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.5224249', geo_lon:  '38.3020044' },
    { city: 'Ярославль', address: 'г Ярославль', federal_district: 'Центральный', region: 'Ярославская', timezone:  'UTC+3', geo_lat: '57.6215477', geo_lon:  '39.8977411' },
]