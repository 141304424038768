<template>
    <div class="block" id="restore-pwd-form">

        <h3>Восстановление пароля</h3>

        <form autocomplete="off" @submit.prevent="sendData">

            <pe-input v-model="email" :error="errors.email"
                      type="email" placeholder="Email" autocomplete="off"></pe-input>

            <pe-input v-model="password" :error="errors.password"
                      type="password" placeholder="Пароль"
                      autocomplete="off"></pe-input>

            <div class="error" v-if="errors.token">{{errors.token}}</div>

            <button type="submit" class="btn">Сохранить пароль</button>
        </form>

    </div>
</template>

<style lang="scss" scoped>
@import "./src/scss/vars";
    #restore-pwd-form {
        margin: 50px auto;
        width: 600px;
        box-sizing: border-box;

        .btn {
            margin-top: 40px;
            width: 100%;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        margin: 10px 0 40px 0;
    }

    .error{
      color: $colorRed;
    }
</style>

<script>

    import peInput from "../../components/peInput";
    import axios from 'axios'
    import {setErrors} from "../../other";

    export default {

        components: {
            peInput,
        },

        data() {
            return {
                send: false,
                errors: {
                    email: null,
                    password: null,
                    token: null,
                },
                email: null,
                password: null,
            }
        },

        methods: {
            sendData() {
                this.errors.token = null;
                axios.post('/api/account/new', {
                    email: this.email,
                    password: this.password,
                    token: this.$route.params.token
                }).then((response) => {
                    this.$router.push('/')
                }).catch((err) => {
                    if(err.response.status === 400){
                        this.errors.token = 'Недействительный токен';
                    } else {
                        setErrors(this.errors, err.response.data)
                    }
                })
            }
        }
    }
</script>